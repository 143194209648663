import { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import { filterRows} from '../../helpers'
import Pagination from '../../Pagination'
import {  getReFunds,getPEGlobalSearchCompaniesApi } from '../../../Config/config'
import CompanyLogo from '../../CompanyLogo';
import RandomLogo from '../../RandomLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../../TableView.css'
import { PeFilterContext } from '../../../Contexts/PeFilterContext'
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { routeNames } from '../../../routeSegments';
import NoDataPlaceholder from '../../shared/NoDataPlaceholder';
import Loader from '../../Loader';
import OverflowToolTip from '../../shared/OverflowToolTip';
import AddNotePopover from '../../shared/AddNotePopover';
import Constants from '../../../Constants';
import { useRef } from 'react';
import DeleteItemsFromGroup from '../../shared/DeleteItemsFromGroup';
import TwolineOverflowToolTip from '../../shared/TwolineOverflowToolTip';


/**
 * The `Table` component is a JavaScript function that renders a table with dynamic data and allows for
 * sorting and pagination.
 * @returns The `Table` component is returning JSX elements, including a table with headers and rows of
 * data, pagination component, and other elements.
 */
const Table = ({ columns, menu, sub_type_master_id }) => {

  const { monthPickerValue, setIsMonthPickerChanged,setIsTagClosed, refineSearchFilters ,groupId} = useContext(PeFilterContext);

  const { rows, setRows, data, setData, isLoad, setIsLoad,setIsDataLoad, currentPage, setCurrentPage, globalSearchResult } = useContext(TableDataContext);
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'desc', orderBy: 'fundingDate' })
  // const [showNote, setShowNote] = useState(false)
  const location = useLocation();
  const abortRef = useRef(null) 
  const[reload, setReload] = useState(false)
  /**
   * The function `findActivePage` sets the current page, triggers a loading state, and renders a table
   * with specific parameters.
   * @param page - The "page" parameter is used to specify the current page number. It is passed to the
   * "findActivePage" function to update the current page state.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    setIsLoad(true)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    // setShowNote(false)
    // setShowId(null)
  }


  /* The above code is a useEffect hook in JavaScript. It is used to handle side effects in functional
  components. */
  useEffect(() => {
const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order ,groupId) 
    return () => {
      abortController.abort();
    }
  }, [monthPickerValue, refineSearchFilters ,groupId ,reload]); 

  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false


  /* The above code is a JavaScript function that is used to render a table. It takes several
  parameters such as page number, month range, column name, sorting order, and group ID. */
  const tableRender = useCallback((page, month, column, order ,groupId) => {
    setIsTagClosed(0)
    setIsMonthPickerChanged(0)
    axios(`${ getReFunds}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:{

        "date_range": {
          "from_date": moment(month[0]).format("YYYY-MM-DD"),
          "to_date": moment(month[1]).format("YYYY-MM-DD")
        },
        "order_by": {
          "column": column,
          "direction": order
        },
        "page": page,
        "fund_ids": refineSearchFilters[location.pathname]?.funds?.map(option => option.id),
        "fund_deal_ids" : isFromGlobalSearch() ? globalSearchResult?.re_fund : [] ,
        "sector_type": refineSearchFilters[location.pathname]?.fundSector?.map(option => option.id),
        "capital_source": refineSearchFilters[location.pathname]?.capitalSource?.map(option => option.id),
        "investor_ids":refineSearchFilters[location.pathname]?.investors?.map(option => option.id),
        "raising_status": refineSearchFilters[location.pathname]?.fundStatus?.map(option => option.id),
        "size": (refineSearchFilters[location.pathname]?.fundSize?.length ?? 0) !== 0 ? {
          "start": refineSearchFilters[location.pathname]?.fundSize?.at(0)?.name,
          "end": refineSearchFilters[location.pathname]?.fundSize?.at(1)?.name,
        } : null
        ,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }
       
      },
      headers: {

        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          console.log(response?.data, "axios api response")
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.fundList)         
          setData(response?.data)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);
  console.log(rows, "fundrows")

  // const addNote = (id) => {
  //   setShowNote(!showNote)
  //   setShowId(id)
  // };


  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  console.log(filteredRows, "filteredrowspe");
 
  const count = filteredRows.length

  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   * @param accessor - The `accessor` parameter is a value that represents the property or key of an
   * object that you want to sort by. It is used to identify the specific property or key that you want
   * to sort the data by.
   * @param order - The "order" parameter is used to specify the sorting order. It can have two
   * possible values: 'asc' for ascending order and 'desc' for descending order.
   */
  const handleSort = (accessor, order) => {
    setIsLoad(true);
    setRows([])

    setCurrentPage(currentPage)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }

  /**
   * The function `addCommasToNumber` takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number to which commas need to be added.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /* The above code is using the useEffect hook in React to log the value of
  `location?.state?.isFromGlobalSearch` whenever it changes. The `location` object is likely coming
  from React Router and is used to access the current URL location. The code is using optional
  chaining (`?.`) to safely access nested properties in case any of them are undefined. */
  useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])
  
  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className="overflow-x-auto scrollbar  rounded-b-[6px]">
              <table className=" tableFunds w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className=" block h-[15px]" />
                          }
                          return <SortAscendingIcon className=" block h-[15px]" />
                        } else {
                          return <SortIcon className=" block h-[15px] " />
                        }
                      }
                      return (

                        <th
                          className={i == 0 || i == 1 ? "text-left bg-[#EBEBEB] lg:relative sticky left-0 md:pl-5 md:pr-5 pl-5 pr-[9rem] lg:pr-[1rem]" : i == 4 ? "py-2 font-sans_book_body" : i == 3 ? "px-8 lg:px-5" : "px-5 " + (" py-2 font-sans_book_body")}
                          key={i}
                        >
                          <span
                            className={`flex items-center ${column.accessor === "size" ? "justify-end" : ""} whitespace-nowrap`}
                          >
                            {column.accessor === "size" ? (
                              <div className="flex flex-col text-center">
                                <span>Size </span>
                                <span>(US$ M)</span>
                              </div>
                            ) : column.accessor === "add" ? (
                              <div className="flex flex-col text-center">
                                <span>Create</span>
                                <span>your list</span>
                              </div>
                            ) : (
                              <>
                                {column.label || "--"}
                              </>
                            )}

                            {column.accessor !== "add" && (
                              <button onClick={() => handleSort(column.accessor)} className="flex items-center">
                                {sortIcon()}
                              </button>
                            )}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[13pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rows.length > 0 ?
                        rows?.map((row, i) => {
                          console.log(row,"funds menu")
                          return (

                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td width="25%" className="tableTd px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate" >
                                {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                  <RandomLogo name={row?.firm_name} />
                                  :
                                  <CompanyLogo name={row?.firm_name} id={row?.firm_id} page="company page" logo={row?.logo_path} />
                                }
                                <OverflowToolTip component={
                                  <NavLink className="hover:text-[#BC8B20] text-[#333333]" 
                                  state={{ prevPath: location.pathname }} 
                                  to={menu == "inv_funds" ?
                                    routeNames.re_investors_funds_detail(row.firm_id, row.deal_id)
                                :""}>
                                    {row.firm_name ? row.firm_name : "--"}
                                  </NavLink>} />
                               
                              </td>
                              <td className="w-[25%] px-5 py-3 font-sans_book_body">
                                <div className="whitespace-normal break-words">
                                  <TwolineOverflowToolTip
                                    className="w-[10rem] lg:w-auto lg:max-w-[20rem] 2xl:max-w-[30rem] line-clamp-2 leading-relaxed"
                                    component={
                                      <NavLink 
                                        className="hover:text-[#BC8B20] text-[#333333] whitespace-normal break-words"
                                        state={{ prevPath: location.pathname }} 
                                        to={menu === "inv_funds" ?
                                          routeNames.re_investors_funds_detail(row.firm_id, row.deal_id) : ""
                                        }
                                      >
                                        {row.fund_name ? row.fund_name : "--"}
                                      </NavLink>
                                    }
                                  />
                                </div>
                              </td>
                              <td width="1%" className="  pl-[1.2rem] py-3 font-sans_book_body text-left" > <a >{row.type ? row.type : "--"}</a></td>
                              <td width="9%" className=" pr-[3rem] px-5 py-3 font-sans_book_body text-right " > <a >{row.size !=0 || row.size != 0.00  ? addCommasToNumber(row.size) : "--"}</a></td>
                              {/* <td width="5%" className="  pr-[2.5rem] lg:pr-[3rem] px-5 py-3 font-sans_book_body text-right  " ><a >{row.amount_raised ? row.amount_raised : "--"}</a></td> */}
                              <td width="1%" className="  tableTd pl-[1.2rem] py-3 font-sans_book_body text-left" ><a >{row.status ? row.status : "--"}</a></td>
                              <td className="w-[15%] px-5 py-3 font-sans_book_body">
                                <div className="whitespace-normal break-words">
                                  <TwolineOverflowToolTip
                                    className="w-[10rem] lg:w-auto lg:max-w-[20rem] 2xl:max-w-[30rem] line-clamp-2 leading-relaxed"
                                    component={
                                      row.capital_source ? row.capital_source : "--"
                                    }
                                  />
                                </div>
                              </td>
                              <td width="5%" className="  pl-4 py-3 font-sans_book_body " >{row.date
                                ? <NavLink
                                  className="hover:text-[#BC8B20] text-[#333333]"
                                  state={{ prevPath: location.pathname }}
                                  to={menu == "inv_funds"
                                    ? routeNames.re_investors_funds_detail(row.firm_id, row.deal_id)
                                    : ""}>
                                  {row.date}
                                </NavLink>
                                : "--"}
                              </td>

                              <td width="5%" className="px-[10px] py-3 font-sans_book_body " >
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.fundsId} setReload={setReload}/>
                                 :
                                 <div className="flex justify-center">
                                  <AddNotePopover className="mx-3" key={row?.fund_id} companyId={row?.fund_id} companyName={row.firm_name} type_id={Constants.moduleIds.fundsId} investor_id={row.firm_id} deal_id={row.deal_id} sub_type_master_id={sub_type_master_id}/>
                                 </div>

                        }</td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></> : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.no_of_funds && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.no_of_funds}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
      {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
    </>
  )
}

export default Table