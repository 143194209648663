import { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react'
import axios from "axios";
import { Link, NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import { rowsPerPageData, getLimitedPartners, dateRangeFilter,getPEGlobalSearchCompaniesApi } from '../../Config/config'
import CompanyLogo from '../CompanyLogo';
import RandomLogo from '../RandomLogo';
import NewLogo from '../NewLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../Pages/addNote";
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import { TableDataContext } from '../../Contexts/TableDataContext'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import CustomNavigation from '../../Utils/CustomNavigation';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import OverflowToolTip from '../shared/OverflowToolTip';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import AddNotePopover from '../shared/AddNotePopover';
import { getSection } from '../../Utils/utils';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';
import TwolineOverflowToolTip from '../shared/TwolineOverflowToolTip';


 
/* The above code is a JavaScript React component that renders a table. It receives props such as
columns, menu, sub_type_master_id, and props. It uses various hooks and context to manage state and
data fetching. The component renders the table based on the received data and allows sorting and
pagination. It also includes functionality to add notes and delete items from a group. */
const Table = ({ columns, menu ,sub_type_master_id ,props}) => { 
  const { monthPickerValue, setIsMonthPickerChanged,setIsTagClosed, refineSearchFilters ,groupId} = useContext(PeFilterContext);

  console.log(sub_type_master_id,"groupp");
  const { rows, setRows, data, setData, setTableLimitedPartnersId, tableLimitedPartnersId, isLoad, setIsLoad, isDataLoad, setIsDataLoad, globalSearchResult } = useContext(TableDataContext);
  const { limitedPartnerderingColumn } = useContext(TableHeaderContext);
  const location = useLocation();
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: limitedPartnerderingColumn })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)


 

  /**
   * The function `findActivePage` sets the active page, renders a table with specific parameters, and
   * updates the load state.
   */
  const findActivePage = (page) => {
    setActivePage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
    // setIsDataLoad(false)
  }

  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setActivePage(1);
    tableRender(1, monthPickerValue, sort.orderBy, sort.order,groupId)
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    return () => {
      abortController.abort();
    }
  }, [monthPickerValue, refineSearchFilters,groupId,reload]);



  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  /* The above code is a JavaScript React function that is used to render a table. */
  const tableRender = useCallback((page, month, column, order,groupId) => {
    setIsTagClosed(0)
    // axios(`${getLimitedPartners}`, {
    //   method: "POST",
    //   signal: abortRef.current.signal,
    //   data: {
        const category= menu == "inv_l_partner" || menu == "inv_l_partner_vc" ? "investments"
          : menu == "inv_l_partner_exit" || menu == "exit-ma-pe-vc" || menu == "exit-ma-vc" || menu == "exit-pm-pe-vc" || menu == "exit-pm-vc" ? "exits"
            : ""
            const dealCategory = category == "investments"
      ? "PEVC"
      :  "EXIT"
        

    const sortBy = dealCategory === "PEVC"
      ? "lp_name_sort"
     
            : "lp_name_sort"

            axios(`${getLimitedPartners}`, {
              method: "POST",
              signal: abortRef.current.signal,
              data: {
                "category": category,

        "type": menu == "inv_l_partner" ? "pe_vc_investor"
          : menu == "inv_l_partner_vc" ? "vc_investor"
            : menu == "inv_l_partner_exit" ? "pe_vc_exit"
              : menu == "exit-ma-pe-vc" ? "pe_ma_exit"
                : menu == "exit-ma-vc" ? "vc_ma_exit"
                  : menu == "exit-pm-pe-vc" ? "pe_pm_exit"
                    : menu == "exit-pm-vc" ? "vc_pm_exit"
                      : " ",
        "order_by": {
          "column": column,
          "direction": order
        },
         "lt_partners":{
          "city":refineSearchFilters[location.pathname]?.city?.map((city) => city.id),
          "Country": refineSearchFilters[location.pathname]?.countries?.map(
            (country) => country.id
          ),
          "firm_type": refineSearchFilters[location.pathname]?.firmType?.map(
            (firm) => firm.id
          ),
          "limited_partners": refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id),
          "investors": refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id) ,
          sector: refineSearchFilters[location.pathname]?.sector?.map(
            (value) => value.id
          ),
         },
        "page": page,
        LpIds:isFromGlobalSearch() ? globalSearchResult.limited_partner : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }
      },      
      headers: {

        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.message == "The limitedpartner list has been listed successfully") {
          console.log(response?.data, "axios api response lpa")

          setIsLoad(false)
          setIsDataLoad(false)
          setRows([])
          setRows(response?.data?.limitedPartners)
          setData(response?.data)
          setTableLimitedPartnersId([])
          setTableLimitedPartnersId(response?.data?.all_lp_ids)
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);

  /**
   * The addNote function toggles the visibility of a note and sets the id of the note to be shown.
   */
  const addNote = (id) => {
    setShowNote(!showNote)
    setShowId(id)
  }
  const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
  // console.log(calculatedRows, "limitedcalculated")


  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   */
  const handleSort = (accessor, order) => {
    setRows([])
    setActivePage(activePage)
    setIsLoad(true);
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(activePage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }

  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)



  useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar scrollbar-remove rounded-b-[6px] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB]  text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] font-semibold  text-[#666666] text-left">
                  <tr>
                    {columns.map((column,i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={ i==0 || i==2 ? menu == "backedIpos-pe-vc" || menu == "backedIpos-vc" ? "md:pl-5 md:pr-5 pl-5 pr-[16rem] " :i===0 ? " text-left bg-[#EBEBEB] lg:relative  sticky left-0 pl-5":"md:pl-5 md:pr-5 pl-5 pr-[13rem] ":"px-5 " +(" py-2 font-sans_book_body")} key={column.accessor}>
                        {/* <th className="whitespace-nowrap px-5  py-2" key={column.accessor}> */}
                            <span className='flex'>
                            <OverflowToolTip 
                              component={
                                column.accessor === "add" ? (
                                  <div className="flex flex-col text-center">
                                    <span>Create</span>
                                    <span>your list</span>
                                  </div>
                                ) : (
                                  <a className='cursor-text hover:text-[#000000]'>
                                    {groupId?.myList?.length > 0 ? "Delete" : column.label ? column.label : "--"}
                                  </a>
                                )
                              } 
                            />
                              {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                  {console.log(rows,count, "lpa")}
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333]">
                      {rows.length > 0 ?
                        // menu == "inv_l_partner" || menu == "inv_l_partner_vc" ?
                          rows?.map((row, i) => {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                                <td width="40%" className="text-left sticky left-0 lg:relative  text-[#333333]  bg-white  leading-[1.8rem] lg:leading-[2rem]  tableTd px-5 py-3 font-sans_book_body card-animate "style={{whiteSpace: "initial"}} >
                                  <div className='flex '>
                                    <NewLogo id={row.LPId} name={row.InstitutionName} menu={"lp"}/>
                                    <OverflowToolTip  className="w-[10rem] lg:w-auto max-w-[15rem]" page="lp" component={
                                        <NavLink className=' text-start lg:inline flex hover:text-[#BC8B20]' state={{prevPath:location.pathname}} to={{
                                          pathname: menu == "inv_l_partner_vc" || menu==="exit-ma-vc" || menu==="exit-pm-vc" 
                                          ? routeNames.vcInvestorsLimitedPartnerById(row.LPId) 
                                          :routeNames.investorsLimitedPartnerById(row.LPId),
                                          search: getSection(menu == "inv_l_partner" ? Constants.primaryMasterSubIds.pe_vc_limited_partners_id 
                                                            : menu=="inv_l_partner_exit" ? Constants.primaryMasterSubIds.pe_vc_exits_limited_partners_id 
                                                            : menu=="exit-ma-pe-vc" ? Constants.primaryMasterSubIds.pe_vc_ma_limited_partners_id
                                                            : menu=="exit-pm-pe-vc" ? Constants.primaryMasterSubIds.pe_vc_pm_limited_partners_id
                                                            : Constants.primaryMasterSubIds.pe_vc_limited_partners_id )
                                          }} >
                                           {row.InstitutionName ? row.InstitutionName : "--"}
                                       </NavLink>
                                    }
                                    />                                    
                                  </div>
                                </td>
                                <td width="15%" className="title px-5 py-3 font-sans_book_body text-left whitespace-nowrap"><OverflowToolTip component={<a >{row.TypeOfInstitution ? row.TypeOfInstitution : "--"}</a>}/></td>
                                <td className="w-[30%] px-5 py-3 font-sans_book_body text-left">
                                  <div className="whitespace-normal break-words">
                                    <TwolineOverflowToolTip
                                      className="w-[14rem] lg:w-auto line-clamp-2 leading-relaxed"
                                      page="lp"
                                      component={
                                        <a className="text-start hover:text-[#BC8B20] cursor-text whitespace-normal break-words">
                                          {row?.investors_array?.length > 0 ? (
                                            row?.investors_array?.map((investor, i) => (
                                              <>
                                                {row?.investors_array?.length - 1 === i ? (
                                                  <CustomNavigation
                                                    className="hover:text-[#BC8B20]"
                                                    mainModule={
                                                      menu === "inv_l_partner_vc" || menu === "exit-ma-vc" || menu === "exit-pm-vc"
                                                        ? "vc"
                                                        : "pe"
                                                    }
                                                    module="investor"
                                                    name={investor.Investor}
                                                    entityId={investor?.InvestorId}
                                                  />
                                                ) : (
                                                  <CustomNavigation
                                                    className="hover:text-[#BC8B20]"
                                                    mainModule={
                                                      menu === "inv_l_partner_vc" || menu === "exit-ma-vc" || menu === "exit-pm-vc"
                                                        ? "vc"
                                                        : "pe"
                                                    }
                                                    module="investor"
                                                    name={investor.Investor + ", "}
                                                    entityId={investor?.InvestorId}
                                                  />
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            "--"
                                          )}
                                        </a>
                                      }
                                    />
                                  </div>
                                </td>

                                <td width="5%" className=" px-[10px] py-3 font-sans_book_body " >
                                {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.limitedPartnerId} setReload={setReload}/>
                                 :
                                <AddNotePopover className="mx-3" key={row.LPId} companyId={row.LPId} companyName={row.InstitutionName} type_id={Constants.moduleIds.limitedPartnerId} sub_type_master_id={sub_type_master_id}/>
                                }
                                </td>
                              </tr>
                            )
                          })
                        //   : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                        : <></>
                        }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.count && rows.length > 0 ? (
        <div className='mb-5'>
        <Pagination
          className="pagination-bar"
          currentPage={activePage}
          totalCount={data?.count}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />
        </div>

      ) : (
        ""
      )}

      {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
    </>
  )
}

export default Table