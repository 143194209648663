import React from "react";
import { Mail, Send } from "lucide-react";

import sequoia from "../../assets/assets/customers/Sequoia_Capital_logo.png";
import accel from "../../assets/assets/customers/Accel-40-Years-Black.png";
import apollo from "../../assets/assets/customers/Apollo_Global_Management_logo.png";
import kkr from "../../assets/assets/customers/Kohlberg_Kravis_Roberts_(logo).png";
import blackstone from "../../assets/assets/customers/The_Blackstone_Group_logo.png";
import pwc from "../../assets/assets/customers/PricewaterhouseCoopers_Logo.png";
import deloitte from "../../assets/assets/customers/Deloitte (1).png";
import kpmg from "../../assets/assets/customers/kpmg-logo.png";
import standardChartered from "../../assets/assets/customers/Standard_Chartered.png";
import insead from "../../assets/assets/customers/insead.png";
import iimA from "../../assets/assets/customers/IIMA_LOGO.png";
import mdiG from "../../assets/assets/customers/MDI-Gurugon.png";
import support1 from "../../assets/assets/support/image4.jpg";
import support2 from "../../assets/assets/support/image2.png";
import support3 from "../../assets/assets/support/image3.png";
import support4 from "../../assets/assets/support/image5.png";
import "../media/media.css";
import Media from "../media/index";

const Customers = () => {
  const customers = [
    { name: "sequoia", logo: sequoia },
    { name: "accel", logo: accel },
    { name: "apollo", logo: apollo },
    { name: "kkr", logo: kkr },
    { name: "Blackstone", logo: blackstone },
    { name: "pwc", logo: pwc },
    { name: "deloitte", logo: deloitte },
    { name: "KPMG", logo: kpmg },
    { name: "Standard Chartered", logo: standardChartered },
    { name: "INSEAD", logo: insead },
    { name: "IIM-A", logo: iimA },
    { name: "MDI-Gurugon", logo: mdiG },
  ];
  const duplicatedCustomers = [...customers, ...customers];

  const animationStyle = {
    animationName: "circularMotion",
    animationDuration: "30s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDelay: "1s",
  };

  const supporterLogos = [
    { logo: support1, link: "https://avainsights.in/" }, 
    { logo: support2, link: "https://www.dcadvisory.com/" },
    { logo: support3, link: "https://thglobal.com/" },
    { logo: support4, link: "https://vedacorp.com/" }
  ];

  return (
    <div className="xl:w-full overflow-x-hidden">
      <div className="bg-slate-100 py-12" id="Customers">
        <h2 className="text-3xl container mx-auto px-4 max-w-5xl font-bold text-center pb-8  text-gray-900">
          Our Customers
        </h2>
      <div className="slider pb-4">
        <div
          className="flex items-center justify-center gap-12 ease-in-out slide-track mt-4"
          // style={animationStyle}
        >
          {duplicatedCustomers.map((customer) => (
            <img
              src={customer.logo}
              alt={customer.name}
              key={customer.name}
              className="w-20  cursor-pointer hover:ease-in-out"
            />
          ))}
          {/* {customers.map((customer) => (
            <img
              src={customer.logo}
              alt={customer.name}
              key={customer.name}
              className="w-20 cursor-pointer hover:ease-in-out"
            />
          ))} */}
        </div>
      </div>

      </div>
      <Media />
      {/* Careers Section */}
      <div className=" mx-auto px-4 py-12 bg-slate-100" id="careers">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-900">
          Careers
        </h2>
        <div className="text-center max-w-2xl mx-auto ">
          <p className="text-lg text-gray-600 mb-4">
            We're building a team of innovative thinkers and problem solvers.
          </p>
          <p className="text-lg text-gray-600 mb-6">
            If you're passionate about driving strategic insights and want to be part of a dynamic workplace, we want to hear from you.
          </p>
          <div className="flex items-center justify-center space-x-2 text-lg text-gray-700">
            <Mail className="text-[#bb8b1f]" size={24} />
            <a
              href="mailto:careers@ventureintelligence.in"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:underline text-[#bb8b1f] transition-colors"
            >
              careers@ventureintelligence.in
            </a>
          </div>
        </div>
        {/* <div className="flex justify-center">
          <a
            href="mailto:careers@ventureintelligence.in"
            className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition duration-300 ease-in-out flex items-center space-x-2"
          >
            <span>Send Resume</span>
            <Send size={20} />
          </a>
        </div> */}
      </div>

      {/* Supported By Section */}
      <div className="container mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold text-center mb-10 text-gray-900">
          Supported By
        </h2>
        <div className="flex flex-wrap gap-8 justify-around mx-auto px-[10%]">
          {supporterLogos.map((supporter, index) => (
            <a
              key={index}
              href={supporter.link}
              target="_blank"
              rel="noopener noreferrer"
              className="p-4 rounded-md shadow-md flex items-center justify-center hover:shadow-lg transform hover:scale-105 transition-all duration-300 w-full sm:w-1/2 md:w-1/3 lg:w-auto"
            >
              <img
                src={supporter.logo}
                alt={`Supporter ${index + 1}`}
                className="object-contain max-w-full max-h-24"
              />
            </a>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Customers;