import { Popover } from 'antd';
import React, { useRef, useEffect, useState } from 'react';

const TwolineOverflowToolTip = (props) => {
    const textElementRef = useRef(null);
    const [hoverStatus, setHover] = useState(false);
    const [page, setPage] = useState(props?.page);

    const compareSize = () => {
        const element = textElementRef?.current;
        if (!element) return;

        // Check if the element is overflowing
        const isOverflowing = element.scrollHeight > element.clientHeight;

        // Only show tooltip if the text is actually truncated
        setHover(isOverflowing);
    };

    useEffect(() => {
        compareSize();
    }, [props?.component, props?.originalContent]);

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => {
            window.removeEventListener('resize', compareSize);
        };
    }, []);

    return (
        <div
            ref={textElementRef}
            className={`w-full block ${props?.className || ''}`}
            style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                wordBreak: 'break-word',
            }}
        >
            {!hoverStatus ? (
                props?.originalContent || props?.component
            ) : (
                <Popover
                    placement={props?.isTopCard ? 'bottomLeft' : 'topLeft'}
                    content={props?.component}
                    title={props?.title}
                    trigger="hover"
                >
                    {props?.originalContent || props?.component}
                </Popover>
            )}
        </div>
    );
};

export default TwolineOverflowToolTip;
