import { useState, useMemo, useContext, useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { filterRows } from '../helpers'
// import { Pagination } from '../Pagination_old'
import Pagination from '../Pagination'
import {  getInvestorsApi,getPEDealsApi,getPEGlobalSearchCompaniesApi} from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
// import {PeFilterContext} from '../Contexts/PeFilterContext';
import axios from "axios";
import moment from "moment";
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableDataContext } from '../../Contexts/TableDataContext';
import CompanyLogo from '../../Components/CompanyLogo';
import RandomLogo from '../RandomLogo';
import CustomNavigation from '../../Utils/CustomNavigation';
import  { routeNames } from '../../routeSegments';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import OverflowToolTip from '../shared/OverflowToolTip';
import AddNotePopover from '../shared/AddNotePopover';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { getSection } from '../../Utils/utils';
import TwolineOverflowToolTip from '../shared/TwolineOverflowToolTip';
import NewLogo from '../NewLogo';


/**
 * This is a JavaScript function that renders a table based on the provided columns, menu, order, and
 * orderBy parameters.
 */
const Table = ({ columns, menu, order, orderBy, sub_type_master_id}) => {
 
  const { monthPickerValue, isMonthPickerChanged, setIsMonthPickerChanged,  setIsTagClosed,  refineSearchFilters,groupId ,setGroupId,setMonthPickerValue ,resetDealsByFilter} = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, setIsLoad,  setIsDataLoad, setTableInvestorId, setCurrentFilter, currentPage, setCurrentPage,setTableNewInvestorId,setTableActiveInvestorId, setAllDealIds, globalSearchResult } = useContext(TableDataContext);
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const location = useLocation();
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)


console.log(refineSearchFilters,"ljhkjh")
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)    
  }
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  const tableRender = useCallback(
    (page, month, column, order, groupId) => {
      setIsTagClosed(0);
      setIsMonthPickerChanged(0);

      const category =
        menu == "investor" || menu == "vcinvestor"
          ? "investments"
          : menu == "pevcExit" ||
            menu == "maPeExit" ||
            menu == "ma_vc" ||
            menu == "exits_vc" ||
            menu == "public_pevc" ||
            menu == "public_vc"
          ? "exits"
          : menu == "backedipo" || menu == "vcbackedipo"
          ? "ipos"
          : menu == "angel" || menu == "angel-vc"
          ? "angel"
          : menu == "incubation" || menu == "incubation-vc"
          ? "incubation"
          : "";

      const dealCategory =
        category == "investments"
          ? "PEVC"
          : category == "exits"
          ? "EXIT"
          : category == "ipos"
          ? "IPO"
          : "ANGEL";


          axios(`${getInvestorsApi}`, {
            method: "POST",
            signal: abortRef.current.signal,
            data: {
              category: category,
              type:
                menu == "investor"
                  ? "pe_vc_investor"
                  : menu == "vcinvestor"
                  ? "vc_investor"
                  : menu == "pevcExit"
                  ? "pe_vc_exit"
                  : menu == "maPeExit"
                  ? "pe_ma_exit"
                  : menu == "exits_vc"
                  ? "vc_exit"
                  : menu == "ma_vc"
                  ? "vc_ma_exit"
                  : menu == "public_pevc"
                  ? "pe_pm_exit"
                  : menu == "public_vc"
                  ? "vc_pm_exit"
                  : menu == "backedipo"
                  ? "pe_vc_ipo"
                  : menu == "vcbackedipo"
                  ? "vc_ipo"
                  : "",
              from: isFromGlobalSearch() ? "gs" : "",
              date_range: {
                    from_date: moment(month[0]).format("YYYY-MM-DD"),
                    to_date: moment(month[1]).format("YYYY-MM-DD"),
                  },
              order_by: {
                column: column,
                direction: order,
              },
              page: page,
              industry: refineSearchFilters[location.pathname]?.industry?.map(
                (value) => value.id
              ), //Industries //[1],
              deals_operator : refineSearchFilters[location.pathname]?.deals_operator,
              company_ids : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
              ladvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id)  ,
              tadvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
              sector: refineSearchFilters[location.pathname]?.sector?.map(
                (value) => value.id
              ), //Industries //[1],
              stage: refineSearchFilters[location.pathname]?.stage?.map(
                (value) => value.id
              ),
              round: refineSearchFilters[location.pathname]?.round?.map(
                (value) => value.name
              ),
              [category === 'incubation' ? 'investorIds' : 'investor_ids']: isFromGlobalSearch()
                ? category === 'angel'
                  ? globalSearchResult.angel_investor?.length !== 0
                    ? globalSearchResult.angel_investor
                      : [0]
                : category === 'incubation'
                    ? globalSearchResult?.incubator?.length !== 0
                      ? globalSearchResult?.incubator
                      : [0]
                : globalSearchResult?.pe_investor?.length === 0
                      ? [0]
                : globalSearchResult?.pe_investor
              : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id),
              year_founded: refineSearchFilters[
                location.pathname
              ]?.yearFounded?.at(0) && {
                from: refineSearchFilters[location.pathname]?.yearFounded[0]
                  .name,
                to: refineSearchFilters[location.pathname]?.yearFounded[1].name,
              },
              syndication: refineSearchFilters[
                location.pathname
              ]?.syndication?.at(0)?.value
                ? [
                    refineSearchFilters[location.pathname]?.syndication?.at(0)
                      ?.value,
                  ]
                : [],
              deals_involving: refineSearchFilters[
                location.pathname
              ]?.dealsInvolving?.map((deal) => deal.value),
              country: refineSearchFilters[location.pathname]?.countries?.map(
                (country) => country.id
              ),
              investor_type: refineSearchFilters[
                location.pathname
              ]?.investorType?.map((inv) => inv.id),
              region: refineSearchFilters[location.pathname]?.region?.map(
                (region) => region.id
              ),
              state: refineSearchFilters[location.pathname]?.state?.map(
                (state) => state.id
              ),
              city: refineSearchFilters[location.pathname]?.city?.map(
                (city) => city.id
              ),
              firm_type: refineSearchFilters[location.pathname]?.firmType?.map(
                (firm) => firm.id
              ),
              investor_country: refineSearchFilters[
                location.pathname
              ]?.investorHeadquarters?.map((inv) => inv.id),
              investor_city: refineSearchFilters[
                location.pathname
              ]?.investor_city?.map((inv) => inv.name),
              follow_on_fund_status: refineSearchFilters[
                location.pathname
              ]?.followexitStatus?.map((status) => status.id),
              deal_investor_range:
                (refineSearchFilters[location.pathname]?.dealInvestorRange
                  ?.length ?? 0) != 0
                  ? {
                      from: refineSearchFilters[
                        location.pathname
                      ]?.dealInvestorRange?.at(0)?.name,
                      to: refineSearchFilters[
                        location.pathname
                      ]?.dealInvestorRange?.at(1)?.name,
                    }
                  : null,
              exited: refineSearchFilters[location.pathname]?.exited?.map(
                (status) => status.id
              ),
              deal_range: refineSearchFilters[location.pathname]?.dealRange?.at(
                0
              ) && {
                from: refineSearchFilters[location.pathname]?.dealRange[0].name,
                to: refineSearchFilters[location.pathname]?.dealRange[1].name,
              },
              exit_status: refineSearchFilters[
                location.pathname
              ]?.exitStatus?.map((status) => status.id),
              tag:
                refineSearchFilters[location.pathname]?.tags?.length != 0
                  ? {
                      operator:
                        refineSearchFilters[location.pathname]?.operator,
                      data: refineSearchFilters[location.pathname]?.tags?.map(
                        (tag) => tag.name
                      ),
                    }
                  : null,
              group: {
                group_id:
                  groupId?.myList?.map((id) => id.group_id)?.toString() ?? null,
                sub_type_master_id:
                  groupId?.myList?.length > 0 ? sub_type_master_id : null,
              },
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: getToken(),
            },
          })
            .then((response) => {
              if (response?.data?.message == "Success") {
                // console.log(response.data, "incubation axios api response")
                setCurrentFilter(response.config.data);
                setIsLoad(false);
                setIsDataLoad(false);
                setIsMonthPickerChanged(0);
                setRows([]);
                setRows(response?.data?.investors);
                setTableInvestorId([]);
                setTableInvestorId(response?.data?.all_investor_ids);
                setTableActiveInvestorId(
                  response?.data?.most_active_investor_ids
                );
                setTableNewInvestorId(response?.data?.new_investor_ids);
                setAllDealIds(response?.data?.all_deal_ids);
                setData(response?.data);
              }
            })
            .catch((error) => {
              if (
                error?.response?.status == Constants.sessionTerminatedStatus
              ) {
                logout();
              }
              // console.log("API not working", error);
            });
    },
    [isMonthPickerChanged, refineSearchFilters]
  );

  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId)
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue, refineSearchFilters,groupId,reload]);

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  
  const count = filteredRows.length

  const handleSort = (accessor, order) => {
    setCurrentPage(currentPage)
    setRows([])
    setIsLoad(true)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order == 'asc' && prevSort.orderBy == accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order == 'asc' && sort.orderBy == accessor ? 'desc' : 'asc',groupId)
  }

  useEffect(()=>{
    if(groupId?.myList?.length >0){
      if(groupId?.myList[0]?.type =='Investor'){
        if(menu == "incubation" || menu == "incubation-vc"){
          setGroupId({})
          setMonthPickerValue(Constants.initialMonthRange)
        }
      } 
      else if(groupId?.myList[0]?.type =='Incubator'){
        if(menu == "incubation" || menu == "incubation-vc"){         
          setMonthPickerValue(['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")])         
        }
        else{
          setGroupId({})
          setMonthPickerValue(Constants.initialMonthRange)
        }
      } 
      else{
        setMonthPickerValue(Constants.initialMonthRange)
      }
    }
  },[])


  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])


  return (
    <> 
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="w-full p-1 bg-grey-50 divide-y" width="50%">
              <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666] text-left">
                <tr>
                  {columns.map((column, i) => {
                    const sortIcon = () => {
                      if (column.accessor == sort.orderBy) {
                        return sort.order == 'asc' 
                          ? <SortDescendingIcon className="pl-2 block h-[15px]" />
                          : <SortAscendingIcon className="pl-2 block h-[15px]" />;
                      }
                      return <SortIcon className="pl-2 block h-[15px] " />;
                    };

                    return (
                      <th
                        className={
                          i === 0
                            ? menu === "backedIpos-pe-vc" || menu === "backedIpos-vc"
                              ? "md:pl-5 md:pr-5 pl-5 pr-[10rem] lg:pr-[9rem] text-left lg:relative bg-[#EBEBEB] sticky left-0"
                              : "text-left bg-[#EBEBEB] lg:relative sticky left-0 pl-5"
                            : column.accessor === "date"
                            ? "pl-3 py-2 font-sans_book_body w-[120px]"
                            : column.accessor === "latest_deal_amount" || column.accessor === "total_funding" || column.accessor === "amount_realized"
                            ? "py-2 font-sans_book_body w-[120px] text-right"
                            : column.accessor === "city_sort" || column.accessor === "existed_companies" || column.accessor === "last_investment_made_sort"
                            ? "pl-5 py-2 font-sans_book_body"
                            : "px-5 py-2 font-sans_book_body"
                        }
                        key={i}
                      >
                        <span className={`flex items-center ${column.accessor === "latest_deal_amount" || column.accessor === "total_funding" || column.accessor === "amount_realized" ? 'justify-end' : ''} whitespace-nowrap`}>
                          {column.accessor === "total_funding" || column.accessor === "amount_realized" || column.accessor === "latest_deal_amount" ? (
                            <div className="flex flex-col text-right">
                              <span>
                                {column.accessor === "total_funding" 
                                  ? "Total Funding" 
                                  : column.accessor === "amount_realized" 
                                  ? "Amount Realized" 
                                  : "Amount"}
                              </span>
                              <span className="block text-xs text-gray-600 text-center w-full">(US$ M)</span>
                            </div>
                          ) : column.accessor === "portfolio_companies" && column.label === "Portfolio (Cos / Deals)" || column.accessor === "portfolio" ? (
                            <div className="flex flex-col text-center">
                              <span>Portfolio</span>
                              <span className="block text-xs text-gray-600">(Cos / Deals)</span>
                            </div>
                          ) : column.accessor === "add" ? (
                            <div className="flex flex-col text-center">
                              <span>Create</span>
                              <span>your list</span>
                            </div>
                          ): column.accessor === "funds_count" ? (
                            <div className="flex flex-col text-center">
                              <span>No. of </span>
                              <span>funds</span>
                            </div>
                          )
                          : column.accessor === "total_invested" ? (
                            <div className="flex flex-col text-center">
                              <span>Total invested</span>
                              <span>(US$ M)</span>
                            </div>
                          )
                          : column.accessor === "last_investment_made_sort" ? (
                            <div className="flex flex-col text-center">
                              <span>Last Investment</span>
                              <span>Made</span>
                            </div>
                          )
                          : column.accessor === "existed_companies" ? (
                            <div className="flex flex-col text-center">
                              <span>Exited Cos</span>
                              <span>(Partial/Full)</span>
                            </div>
                          )
                          : column.accessor === "top_portfolio_companies_sort" ? (
                            <span className="whitespace-nowrap">{column.label || "--"}</span>
                          ) : (
                            <>{column.label || "--"}</>
                          )}

                          {column.accessor !== "add" && (
                            <button onClick={() => handleSort(column.accessor)} className="ml-2 flex items-center">
                              {sortIcon()}
                            </button>
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>


                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt]  lg:text-[14px] text-[#333333] lg:leading-[16px] font-sans_book_body text-left">
                      {rows.length > 0 ?
                        menu == "investor" || menu == "vcinvestor" ?
                          rows?.map((row, i) => {
                            // console.log(menu,'check menu');
                            return (
                              <tr key={i} className="  group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text">

                              <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                              <NewLogo id={row.investor_id} menu={"investor"} name={row.investor_name} />
                                <div className="whitespace-normal break-words">
                                  <OverflowToolTip
                                    className="w-[11rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                    component={
                                      <NavLink
                                        className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                        state={{
                                          prevPath: location.pathname,
                                        }}
                                        to={{
                                          pathname:
                                            menu == "vcinvestor"
                                              ? routeNames.vcInvestorById(row.investor_id)
                                              : routeNames.peInvestorById(row.investor_id),
                                          search: getSection(
                                            menu == "investor" || menu == "vcinvestor"
                                              ? Constants.primaryMasterSubIds.pe_vc_investments_id
                                              : menu == "pevcExit" || menu == "exits_vc"
                                              ? Constants.primaryMasterSubIds.pe_vc_exits_id
                                              : menu == "maPeExit" || menu == "ma_vc"
                                              ? Constants.primaryMasterSubIds.pe_vc_ma_id
                                              : menu == "public_pevc" || menu == "public_vc"
                                              ? Constants.primaryMasterSubIds.pe_vc_pm_id
                                              : menu == "backedipo" || menu == "vcbackedipo"
                                              ? Constants.primaryMasterSubIds.pe_vc_backed_ipos_id
                                              : menu == "angel" || menu == "angel-vc"
                                              ? Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
                                              : menu == "incubation" || menu == "incubation-vc"
                                              ? Constants.primaryMasterSubIds.pe_vc_incubation_id
                                              : null
                                          ),
                                        }}
                                      >
                                        {row.investor_name ? row.investor_name : "--"}
                                      </NavLink>
                                    }
                                  />
                                </div>
                              </td>
                                <td className="w-[2%] px-5 pr-[2.8rem] py-3 font-sans_book_body text-right"><a>{row.portfolio_companies !=0 ? addCommasToNumber(row.portfolio_companies) : "--"} / {row.portfolio_deals !=0 ? addCommasToNumber(row.portfolio_deals) : "--"}</a></td>
                                <td className="px-5 py-3 font-sans_book_body whitespace-normal break-words sm:max-w-[15rem] 2xl:max-w-[30rem] font-medium leading-relaxed">
                                  <TwolineOverflowToolTip 
                                    className="w-[20rem] lg:w-auto line-clamp-2 leading-relaxed"
                                    component={
                                      <div className="text-start cursor-text whitespace-normal break-words">
                                        {row?.top_portfolio_companies_detail?.length > 0 ? (
                                          row?.top_portfolio_companies_detail?.map((investor, i) => (
                                            <>
                                              <CustomNavigation 
                                                mainModule={menu == "vcinvestor" ? 'vc' : 'pe'} 
                                                module="company" 
                                                name={investor.company_name + (row?.top_portfolio_companies_detail?.length - 1 === i ? '' : ', ')} 
                                                entityId={investor?.company_id} 
                                              />
                                            </>
                                          ))
                                        ) : (
                                          "--"
                                        )}
                                      </div>
                                    }
                                  />
                                </td>
                                <td className="w-[5%] px-5 pr-[1.8rem] text-right py-3 font-sans_book_body " > <a  >{row.funds_count !=0 ? addCommasToNumber(row.funds_count) : "--"}</a></td>
                                <td className="w-[2%] text-right pr-[2.9rem] 2xl:pr-[3.5rem] py-3 font-sans_book_body " ><a >{row.total_invested ? row.total_invested : "--"}</a></td>
                                <td className="w-[5%] px-[10px] py-3 font-sans_book_body " >
                                {groupId?.myList?.length >0 ? 
                                    <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={2} setReload={setReload}/>
                                          :
                                  <AddNotePopover className="mx-3" key={row.investor_id} companyId={row.investor_id} companyName={row.investor_name} type_id={2} sub_type_master_id={sub_type_master_id}/>
                                }

                                </td>
                              </tr>
                            )
                          })
                          :
                          menu == "backedipo" || menu == "vcbackedipo" ?
                            rows?.map((row, i) => {
                              return (
                                <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={row.id}>

                                  <td className="w-[25%] leading-[1.8rem] text-left sticky lg:relative left-0  text-[#333333]  bg-white  lg:leading-[2rem]  px-5 py-3 font-sans_book_body" >
                                    <NewLogo id={row.investor_id} menu={"investor"} name={row.investor_name} />

                                    <NavLink state={{ prevPath: location.pathname }} to={{
                                      pathname:menu == "vcbackedipo" ? routeNames.vcIpoInvestorById(row.investor_id) : routeNames.peIpoInvestorById(row.investor_id),
                                      search: getSection(Constants.primaryMasterSubIds.pe_vc_backed_ipos_id)}} >
                                      <a className="flex lg:inline text-start  hover:text-[#BC8B20] font-medium text-[#333333]" >{row.investor_name ? row.investor_name : "--"}</a>
                                    </NavLink></td>

                                  <td className="w-[5%] pr-[3rem] lg:pr-[3rem] xl:pr-[3.9rem] 2xl:pr-[7rem] py-3 px-3 font-sans_book_body text-right" >
                                    <a >{row.ipos !=0 ? addCommasToNumber(row.ipos) : "--"}</a>
                                  </td>
                                  <td className="w-[20%] px-5 py-3 font-sans_book_body whitespace-nowrap" style={{whiteSpace: "initial"}}>
                                    <OverflowToolTip className=" w-[15rem] lg:w-auto sm:max-w-[17rem] 2xl:max-w-[25rem] font-medium" component={<CustomNavigation mainModule={menu == "vcbackedipo" ? 'vc' : 'pe'} module="company" name={row.latest_ipo} entityId={row?.latest_ipo_id} />} />
                                  </td>
                                  <td className="w-[5%] pr-[3rem]  lg:pr-[2rem] xl:pr-[2.7rem] 2xl:pr-[4rem] py-3 px-3 font-sans_book_body text-right" >
                                    <a >{row.latest_ipo_size !=0 || row.latest_ipo_size !=0.00  ? addCommasToNumber(row.latest_ipo_size) : "--"}</a>
                                  </td>
                                  <td className="w-[5%] px-[10px] py-3 font-sans_book_body" >
                                  {groupId?.myList?.length >0 ? 
                                    <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={2} setReload={setReload}/>
                                          :
                                    <AddNotePopover className="mx-3" key={row.investor_id} companyId={row.investor_id} companyName={row.investor_name} type_id={2} sub_type_master_id={sub_type_master_id}/>
                                  }
                                  </td>
                                </tr>
                              )
                            })
                            :
                            menu == "pevcExit" || menu == "exits_vc" || menu == "maPeExit" || menu == "ma_vc" || menu == "public_pevc" || menu == "public_vc" ?
                              rows?.map((row, i) => {

                                return (
                                  <tr className="group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={row.id}>

                                  <td className="w-[22%] leading-[1.8rem] text-left sticky left-0 lg:relative text-[#333333] bg-white px-5 py-3 font-sans_book_body whitespace-nowrap">
                                  <NewLogo id={row.investor_id} menu={"investor"} name={row.investor_name} />
                                    <div className="whitespace-normal break-words">
                                      <OverflowToolTip
                                        className="w-[10rem] lg:w-auto sm:max-w-[14rem] 2xl:max-w-[20rem] flex flex-row gap-2"
                                        component={
                                          <NavLink
                                            className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                            state={{ prevPath: location.pathname }}
                                            to={{
                                              pathname:
                                                menu === "ma_vc" || menu === "public_vc"
                                                  ? routeNames.vcExitInvestorById(row.investor_id)
                                                  : routeNames.peExitInvestorById(row.investor_id),
                                              search: getSection(
                                                menu === "pevcExit" || menu === "exits_vc"
                                                  ? Constants.primaryMasterSubIds.pe_vc_exits_id
                                                  : menu === "maPeExit" || menu === "ma_vc"
                                                  ? Constants.primaryMasterSubIds.pe_vc_ma_id
                                                  : menu === "public_pevc" || menu === "public_vc"
                                                  ? Constants.primaryMasterSubIds.pe_vc_pm_id
                                                  : null
                                              ),
                                            }}
                                          >
                                            {row.investor_name ? row.investor_name : "--"}
                                          </NavLink>
                                        }
                                      />
                                    </div>
                                  </td>

                                    <td width="2%" className="pr-[2.6rem] 2xl:pr-[3.5rem] py-3  font-sans_book_body text-center"><a >{row.existed_companies !=0 ? addCommasToNumber(row.existed_companies) : "--"}</a></td>
                                    <td className="w-[18%] px-5 py-3 font-sans_book_body">
                                      <div className="whitespace-normal break-words">
                                        <TwolineOverflowToolTip
                                          className="w-[16rem] lg:w-auto  line-clamp-2 leading-relaxed font-medium"
                                          component={
                                            <a className="text-start hover:text-[#000000] cursor-text whitespace-normal break-words">
                                              {row?.top_existed_companies_detail?.length > 0 ? (
                                                row?.top_existed_companies_detail?.map((company, i) => (
                                                  <>
                                                    {row?.top_existed_companies_detail?.length - 1 === i ? (
                                                      <CustomNavigation
                                                        mainModule={menu === "ma_vc" || menu === "public_vc" ? "vc" : "pe"}
                                                        module="company"
                                                        name={company.company_name}
                                                        entityId={company?.company_id}
                                                      />
                                                    ) : (
                                                      <CustomNavigation
                                                        mainModule={menu === "ma_vc" || menu === "public_vc" ? "vc" : "pe"}
                                                        module="company"
                                                        name={company.company_name + ", "}
                                                        entityId={company?.company_id}
                                                      />
                                                    )}
                                                  </>
                                                ))
                                              ) : (
                                                "--"
                                              )}
                                            </a>
                                          }
                                        />
                                      </div>
                                    </td>

                                    <td className="w-[12%] px-5 py-3 font-sans_book_body">
                                      <div className="whitespace-normal break-words">
                                        <TwolineOverflowToolTip
                                          className="w-[9rem] lg:w-auto font-medium line-clamp-2 leading-relaxed"
                                          component={
                                            <a className="text-start hover:text-[#000000] cursor-text whitespace-normal break-words">
                                              <CustomNavigation
                                                mainModule={menu === "ma_vc" || menu === "public_vc" ? "vc" : "pe"}
                                                module="company"
                                                name={row.last_invested_in}
                                                entityId={row?.last_invested_in_id}
                                              />
                                            </a>
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td width="2%" className="tableTd  px-5 py-3 font-sans_book_body " >
                                      <NavLink className="hover:text-[#BC8B20] font-medium" to={menu == "ma_vc" || menu == "public_vc" ? routeNames.vcCompanyExitsDealById(row?.last_invested_in_id, row?.last_investment_made_id): routeNames.peCompanyExitsDealById(row?.last_invested_in_id, row?.last_investment_made_id)} >
                                        {row.last_investment_made ? row.last_investment_made : "--"} 
                                      </NavLink>
                                    </td>
                                    <td width="2%" className="px-[10px] py-3 font-sans_book_body " >
                                    {groupId?.myList?.length >0 ? 
                                    <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={2} setReload={setReload}/>
                                          :
                                      <AddNotePopover className="mx-3" key={row.investor_id} companyId={row.investor_id} companyName={row.investor_name} type_id={2} sub_type_master_id={sub_type_master_id}/>
                                    }
                                    </td>
                                  </tr>
                                )
                              })
                              :
                              menu == "angel" || menu == "angel-vc" ?
                                rows?.map((row, i) => {
                                  return (
                                    <tr className="group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={row.id}>

                                    <td className="md:w-[25%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                        <NewLogo id={row.investor_id} menu={"investor"} name={row.investor_name} />
                                        <div className="whitespace-normal break-words">
                                          <OverflowToolTip
                                            className="w-[11rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                            component={
                                              <NavLink
                                                className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                                state={{ prevPath: location.pathname }}
                                                to={{
                                                  pathname:
                                                    menu === "angel-vc"
                                                      ? routeNames.vcAngelInvestorById(row.investor_id)
                                                      : routeNames.peAngelInvestorById(row.investor_id),
                                                  search: getSection(
                                                    menu === "investor" || menu === "vcinvestor"
                                                      ? Constants.primaryMasterSubIds.pe_vc_investments_id
                                                      : Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
                                                  ),
                                                }}
                                              >
                                                {row.investor_name || "--"}
                                              </NavLink>
                                            }
                                          />
                                        </div>
                                      </td>

                                      <td width="5%" className=" pr-[3rem] py-3 px-3 font-sans_book_body text-right " >
                                        <a >{row.portfolio !=0 ? addCommasToNumber(row.portfolio) : "--"} / {row.portfolio_deals !=0 ? addCommasToNumber(row.portfolio_deals) : "--"}</a> 
                                      </td>

                                      <td width="25%" className="px-5 py-3 font-sans_book_body font-medium whitespace-normal break-words leading-relaxed">
                                        <TwolineOverflowToolTip 
                                          className="w-[12rem] lg:w-auto lg:max-w-[20rem] line-clamp-2 leading-relaxed"
                                          component={
                                            <div className="text-start cursor-text whitespace-normal break-words">
                                              {row?.latest_investments_details?.length > 0 ? (
                                                row?.latest_investments_details?.map((company, i) => (
                                                  <>
                                                    <CustomNavigation 
                                                      mainModule={menu == "angel-vc" ? 'vc' : 'pe'} 
                                                      module="company" 
                                                      name={company.company_name + (row?.latest_investments_details?.length - 1 === i ? '' : ', ')} 
                                                      entityId={company?.company_id} 
                                                    />
                                                  </>
                                                ))
                                              ) : (
                                                "--"
                                              )}
                                            </div>
                                          }
                                        />
                                      </td>
                                      <td width="15%" className="  px-5 py-3 font-sans_book_body " >
                                      <OverflowToolTip  className="w-[12rem] lg:w-auto lg:max-w-[15rem] font-medium"  component={ <CustomNavigation mainModule={menu == "angel-vc" ? 'vc' : 'pe'} module="company" name={row.last_invested_in} entityId={row.last_invested_in_id} />}/>
                                      </td>
                                      <td width="10%" className="tableTd  px-5 py-3 font-sans_book_body " >
                                      <NavLink className="hover:text-[#BC8B20] font-medium" to={menu == "angel-vc" ? routeNames.vcCompanyAngelDealById(row?.last_invested_in_id, row?.last_investment_made_id): routeNames.peCompanyAngelDealById(row?.last_invested_in_id, row?.last_investment_made_id)} >
                                        {row.last_investment_made ? row.last_investment_made : "--"} 
                                      </NavLink>
                                      </td>
                                      <td width="5%" className="px-[10px] py-3 font-sans_book_body " >
                                      {groupId?.myList?.length >0 ? 
                                        <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={2} setReload={setReload}/>
                                          :
                                        <AddNotePopover className="mx-3" key={row.investor_id} companyId={row.investor_id} companyName={row.investor_name} type_id={2} sub_type_master_id={sub_type_master_id}/>
                                      }
                                      </td>
                                    </tr>
                                  )
                                })
                                :
                                menu == "incubation" || menu == "incubation-vc" ?
                                  rows?.map((row, i) => {
                                    return (
                                      <tr className="group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>

                                        <td width="27%" className="leading-[1.8rem]  lg:relative text-left sticky left-0  text-[#333333]  bg-white  lg:leading-[2rem]   px-5 py-3 font-sans_book_body" >
                                          <NewLogo id={row.investor_id} menu={"incubator"} name={row.Incubator} />
                                          <OverflowToolTip className="w-[10rem] lg:w-auto font-medium" component={
                                            <NavLink state={{ prevPath: location.pathname }}
                                              to={{pathname:menu == "incubation-vc" 
                                              ? routeNames.vcIncubationInvestorById(row.investor_id) 
                                              : routeNames.peIncubationInvestorById(row.investor_id),
                                              search: getSection(Constants.primaryMasterSubIds.pe_vc_incubation_id)}} >
                                              <a className='flex lg:inline text-start  hover:text-[#BC8B20] font-medium text-[#333333]'>{row.Incubator ? row.Incubator : "--"} </a>
                                            </NavLink>} />
                                        </td>
                                        <td width="5%" className="pr-[2.7rem] lg:pr-[4rem] xl:pr-[4.5rem] 2xl:pr-[5rem] py-3 px-3 font-sans_book_body text-right " >
                                          <a >{row.Incubatees !=0 ? addCommasToNumber(row.Incubatees) : "--"} </a>
                                        </td>
                                        <td width="25%" className="px-5 py-3 font-sans_book_body tableTd whitespace-normal break-words leading-relaxed">
                                          <TwolineOverflowToolTip 
                                            className="w-[18rem] lg:w-auto sm:max-w-[22rem] 2xl:max-w-[30rem] font-medium line-clamp-2 leading-relaxed"
                                            component={
                                              <div className="text-start cursor-text whitespace-normal break-words">
                                                {row?.latest_incubated_details?.length > 0 ? (
                                                  row?.latest_incubated_details?.map((company, i) => (
                                                    <>
                                                      <CustomNavigation 
                                                        mainModule={menu == "incubation-vc" ? 'vc' : 'pe'} 
                                                        module="company" 
                                                        name={company.company_name + (row?.latest_incubated_details?.length - 1 === i ? '' : ', ')} 
                                                        entityId={company?.company_id} 
                                                      />
                                                    </>
                                                  ))
                                                ) : (
                                                  "--"
                                                )}
                                              </div>
                                            }
                                          />
                                        </td>
                                        <td width="5%" className="tableTd px-[10px] py-3 font-sans_book_body " >
                                        {groupId?.myList?.length >0 ? 
                                          <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={2} setReload={setReload}/>
                                            :
                                          <AddNotePopover className="mx-3" key={row.investor_id} companyId={row.investor_id} companyName={row.Incubator} type_id={Constants.moduleIds.incubators_id} sub_type_master_id={sub_type_master_id}/>
                                        }

                                        </td>
                                      </tr>
                                    )
                                  })


                                  : ""
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[14px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></> : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='w-full h-[17rem] bg-white flex items-center justify-center '>
                    <NoDataPlaceholder />
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative '>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
   

      {data?.num_investors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_investors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      )
        : (
        ""
      )}
        


    </>
  )
}

export default Table
