import { useState, useMemo, useContext, useEffect, useCallback, useRef } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { filterRows } from '../helpers'
import Pagination from '../../Components/Pagination'
import { reGetCompaniesUrl, getPEGlobalSearchCompaniesApi } from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import axios from 'axios';
import { UserContext } from '../../Contexts/UserContext';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import CustomNavigation from '../../Utils/CustomNavigation';
import { routeNames } from '../../routeSegments';
import OverflowToolTip from '../shared/OverflowToolTip';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import NewLogo from '../NewLogo';
import { getSection } from '../../Utils/utils' 
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';
import TwolineOverflowToolTip from '../shared/TwolineOverflowToolTip';


const Table = ({ columns, defualtOrderBy, order, orderBy, menu ,sub_type_master_id}) => {
  const { rows, setRows, setTableCompanyId, data, setData, isLoad, setIsLoad, setIsDataLoad, setCurrentFilter, currentPage, setCurrentPage,setAllDealIds, globalSearchResult} = useContext(TableDataContext);
  const { monthPickerValue, setIsMonthPickerChanged, refineSearchFilters ,groupId,resetDealsByFilter} = useContext(PeFilterContext);
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const abortRef = useRef(null)
  const location = useLocation();
  const { getToken, logout } = useContext(UserContext);
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false
  const[reload, setReload] = useState(false)
 /**
  * The function `findActivePage` sets the current page, renders a table with specific parameters, and
  * sets a flag to indicate that the page is loading.
  * @param page - The "page" parameter represents the current page number.
  */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order ,groupId)
    setIsLoad(true)

  }
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])
  console.log(groupId,'groupIdRE');
  // console.log(refineSearchFilters[[location.pathname]], 're companies refineSearchFilters');

  /* The above code is using a conditional (ternary) operator to assign a value to the variable
  "category" based on the value of the variable "menu". If "menu" is equal to "re", the value of
  "category" will be "re-investments". If "menu" is equal to "re-exit", the value of "category" will
  be "re-exits". If "menu" is equal to "re-ma", the value of "category" will be "other-manda". If
  "menu" is equal to "re-ipo", the value */
  const category = menu == "re" 
      ? "re-investments" 
      : menu == "re-exit" 
        ? "re-exits" 
        : menu == "re-ma" 
          ? "other-manda" 
          : menu == "re-ipo" 
            ? "re-ipo" 
            : ""
const globalSearchCategory = category.replace("-", "_") + "_ids";


 /* The above code is assigning a value to the `dealCategory` variable based on the value of the
 `category` variable. If the `category` is equal to "re-investments", the `dealCategory` will be
 assigned the value "RE_INV". If the `category` is equal to "re-exits", the `dealCategory` will be
 assigned the value "RE_EXIT". If the `category` is equal to "re-ipo", the `dealCategory` will be
 assigned the value "RE_IPO". If the `category` is equal to "other-manda */
  const dealCategory = category == "re-investments"
      ? "RE_INV"
      : category == "re-exits"
        ? "RE_EXIT"
        : category == "re-ipo"
          ? "RE_IPO"
          : category == "other-manda"
          ?"OTHER-MA":""

 /* The above code is assigning a value to the variable "sortBy" based on the value of the variable
 "dealCategory". If "dealCategory" is equal to "RE_INV", the value of "sortBy" will be
 "latest_inv_deal_date_num". If "dealCategory" is equal to "RE_EXIT", the value of "sortBy" will be
 "latest_exit_deal_date_num". If "dealCategory" is equal to "RE_IPO", the value of "sortBy" will be
 "latest_ipos_deal_date_num". If "dealCategory" is equal to "OTHER */
  const sortBy = dealCategory == "RE_INV"
      ? "latest_inv_deal_date_num"
      : dealCategory == "RE_EXIT"
        ? "latest_exit_deal_date_num"
        : dealCategory == "RE_IPO"
          ? "latest_ipos_deal_date_num"
          : dealCategory == "OTHER-MA"
          ? "latest_MA_deal_date_num"
          :""
console.log(location.pathname , "jgfj" , refineSearchFilters[location.pathname])

 /* The above code is a JavaScript function that is used to render a table based on certain parameters. */
  const tableRender = useCallback((page, month, column, order ,groupId) => {
    console.log(month, "ll")
    setIsMonthPickerChanged(0)
    axios(`${reGetCompaniesUrl}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:{
        "category": menu == "re" ? "re-investments" : menu == "re-exit" ? "re-exits" :
          menu == "re-ma" ? "other-manda" : menu == "re-ipo" ? "re-ipo" : "",
        from : isFromGlobalSearch() ? 'gs' : '',
        date_range: {
                from_date: moment(month[0]).format("YYYY-MM-DD"),
                to_date: moment(month[1]).format("YYYY-MM-DD"),
              },
        "order_by": {
          "column": column,
          "direction": order
        },
        "page": page,
        // operator : refineSearchFilters[location.pathname]?.deals_operator,
        ladvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id)  ,
        tadvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
        investor_ids : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id) ,
        company_ids: isFromGlobalSearch() ? (category === "re-investments" && globalSearchResult?.re_investment_cmp_ids.length !== 0) ?
          globalSearchResult?.re_investment_cmp_ids : (category === "re-exits" && globalSearchResult?.re_exit_cmp_ids?.length !== 0) ?
          globalSearchResult?.re_exit_cmp_ids : (category === "re-ipo" && globalSearchResult?.re_ipo?.length !== 0) ?
          globalSearchResult?.re_ipo : [0]
            : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
        "sector_type": refineSearchFilters[location.pathname]?.fundSector?.map(option => option.id),
        "region": refineSearchFilters[location.pathname]?.region?.map(region => region.id),
        "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "type": parseInt(refineSearchFilters[location.pathname]?.type?.map(type => type.id)),
        "company_type": refineSearchFilters[location.pathname]?.companyType?.map(type => type.id),
        "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(inv => inv.id),
        "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
          "from": parseInt(refineSearchFilters[location.pathname]?.dealRange[0].name),
          "to": parseInt(refineSearchFilters[location.pathname]?.dealRange[1].name)
        },
      "sub_sector": refineSearchFilters[location.pathname]?.subSector?.map(sector => sector.id),
        "exit_status": refineSearchFilters[location.pathname]?.exitStatus?.map(status => status.id)
        , group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    },

      },
     
      headers: {
        "Authorization": getToken()
      }
     
    })
      .then(response => {
        if (response?.data?.message == "Success") {
          setCurrentFilter(response.config.data)
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.companies)
          setTableCompanyId([])
          setTableCompanyId(response?.data?.all_companies_ids) 
          setAllDealIds(response?.data?.all_deal_ids)
          setData(response?.data)
          // console.log(response?.data?.companies,"api")
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [monthPickerValue, refineSearchFilters[location.pathname]]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order ,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue ,groupId ,reload]);

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])

  const count = filteredRows?.length

 /**
  * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
  * and order.
  * @param accessor - The `accessor` parameter is a value that represents the property or key of the
  * object that you want to sort by. It is used to identify the specific property or key that you want
  * to sort the data by.
  * @param order - The "order" parameter is used to specify the sorting order. It can have two possible
  * values: "asc" for ascending order and "desc" for descending order.
  */
const handleSort = (accessor) => {
  setCurrentPage(currentPage); // Assuming currentPage is defined elsewhere
  setRows([]);
  setIsLoad(true);
  setIsDataLoad(true);
  setSort((prevSort) => ({
    order: prevSort.orderBy === accessor && prevSort.order === 'desc' ? 'asc' : 'desc',
    orderBy: accessor,
  }));
  const newOrder = sort.orderBy === accessor && sort.order === 'desc' ? 'asc' : 'desc';
  tableRender(currentPage, monthPickerValue, accessor, newOrder, groupId);
};
/**
 * The function `addCommasToNumber` takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string with commas added to the number.
 */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows?.length > 5 ? '':'pr-2')}>
              <table className=" listPage table w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      {console.log(column.accessor,"khljl")}

                      return (
                        <th className="title px-5 py-2 font-sans_book_body" key={i}>
                          <span className='flex'>
                            <OverflowToolTip component={
                              <a className='cursor-text hover:text-[#000000]'>
                                {column.accessor === "add" && groupId?.myList?.length > 0 ? "Delete" :
                                  column.accessor === "add" ? (
                                    <div className="flex flex-col text-center">
                                      <span>Create</span>
                                      <span>your list</span>
                                    </div>
                                  ) 
                                  : column.accessor === "amount" || column.accessor === "amount_m" || column.accessor === "deal_amount" ? (
                                    <div className="flex flex-col text-right">
                                      <span>Amount</span>
                                      <span>(US$ M)</span>
                                    </div>
                                  ) 
                                  : column.accessor === "ipo_size_m" ? (
                                    <div className="flex flex-col text-right">
                                      <span>Deal Size</span>
                                      <span>(US$ M)</span>
                                    </div>
                                  ) 
                                  : column.label ? column.label : "--"}
                              </a>
                            } />
                            {column.accessor !== "add" && (
                              <button onClick={() => handleSort(column.accessor, sort.order)}>
                                {sortIcon()}
                              </button>
                            )}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[13pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rows.length > 0 ?
                        menu == "re" ?
                          rows?.map((row, i) => {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                <NewLogo id={row.company_id} name={row.company} menu={"re"}/>
                                  <div className="whitespace-normal break-words">
                                    <OverflowToolTip
                                      className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                      component={
                                        <NavLink
                                          className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                          state={{ prevPath: location.pathname }}
                                          to={{
                                            pathname: routeNames.reCompanyById(row.company_id),
                                            search: getSection(Constants.primaryMasterSubIds.re_investments_id),
                                          }}
                                        >
                                          {row?.company ? row.company : "--"}
                                        </NavLink>
                                      }
                                    />
                                  </div>
                                </td>

                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[10%]'><a>{row?.city ? row.city : "--"}</a></td>
                                <td className="whitespace-normal break-words px-5 py-3 font-[Squel Sans] w-[18%] font-medium">
                                  <div className="whitespace-normal break-words">
                                    <TwolineOverflowToolTip
                                      className="w-[10rem] lg:max-w-[17rem] 2xl:max-w-[30rem] line-clamp-2 leading-relaxed"
                                      component={
                                        <Link
                                          rel="noopener noreferrer"
                                          state={{ prevPath: location.pathname }}
                                          to={routeNames.reInvestorById(row.investor_id)}
                                          className="text-start hover:text-[#000000] cursor-text whitespace-normal break-words"
                                        >
                                          {row?.investor?.length > 0
                                            ? row?.investor?.map((inve, i) => (
                                                <span key={inve.investor_id}>
                                                  <CustomNavigation
                                                    mainModule={menu == "re" ? "re" : ""}
                                                    module="investor"
                                                    name={
                                                      row?.investor?.length - 1 === i
                                                        ? inve.investor_name
                                                        : inve.investor_name + ", "
                                                    }
                                                    entityId={inve?.investor_id}
                                                    type={inve?.investor_type}
                                                    stake={inve?.stake}
                                                  />
                                                </span>
                                              ))
                                            : "--"}
                                        </Link>
                                      }
                                    />
                                  </div>
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[12%]'>
                                  {row?.deal_date? <Link
                                    className='hover:text-[#BC8B20] font-medium'
                                    to={{
                                      pathname: routeNames.reCompanyInvestmentDealById(row?.company_id, row?.id)
                                    }}
                                  >
                                    {row.deal_date}
                                  </Link> : "--"}
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[10%]'><a>{row?.exit_status ? row.exit_status : "--"}</a></td>
                                <td className='whitespace-nowrap   px-5 py-3 font-[Squel Sans] w-[2%]  text-center pr-[3rem] md:pr-[2rem] lg:pr-[3rem] xl:pr-[3rem] 2xl:pr-[3rem] 3xl:pr-[4rem]'><a>{row?.deal_amount == 0 || row?.deal_amount == 0.00  ? "--" : row.deal_amount}</a></td>

                                  <td className='whitespace-nowrap   py-3 font-[Squel Sans] w-[1%]'>
                                  {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={1} setReload={setReload}/>
                                 : (<div className="flex justify-center">
                                      <AddNotePopover key={row.company_id} companyId={row.company_id} companyName={row.company} type_id={1} sub_type_master_id={sub_type_master_id}/>
                                    </div>) 
                                  }
                          </td>
                          
                            </tr>
                            )
                          })
                          : menu == "re-exit" ?
                            rows?.map((row, i) => {
                              return (
                                <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                  <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                  <NewLogo id={row.company_id} name={row.company} menu={"re"}/>
                                    <div className="whitespace-normal break-words">
                                      <OverflowToolTip
                                        className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                        component={
                                          <NavLink
                                            className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                            state={{ prevPath: location.pathname }}
                                            to={{
                                              pathname: routeNames.reCompanyById(row.company_id),
                                              search: getSection(Constants.primaryMasterSubIds.re_exits_id),
                                            }}
                                          >
                                            {row?.company ? row.company : "--"}
                                          </NavLink>
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[15%]'><a>{row?.city ? row.city : "--"}</a></td>
                                  <td className="whitespace-normal break-words px-5 py-3 font-[Squel Sans] w-[21%]">
                                    <div className="whitespace-normal break-words">
                                      <TwolineOverflowToolTip
                                        className="w-[10rem] lg:max-w-[17rem] 2xl:max-w-[30rem] line-clamp-2 leading-relaxed"
                                        component={
                                          <Link
                                            rel="noopener noreferrer"
                                            className="flex text-start lg:inline lg:whitespace-normal hover:text-[#BC8B20] font-medium"
                                            state={{ prevPath: location.pathname }}
                                            to={routeNames.reInvestorById(row.investor_id)}
                                          >
                                            {row?.investor?.length > 0
                                              ? row?.investor?.map((inv, i) => (
                                                  <span key={inv.investor_id}>
                                                    <CustomNavigation
                                                      mainModule={menu === "re-exit" ? "re" : ""}
                                                      module="investor"
                                                      name={
                                                        row?.investor?.length - 1 === i
                                                          ? inv.investor_name
                                                          : inv.investor_name + ", "
                                                      }
                                                      entityId={inv?.investor_id}
                                                      type={inv?.investor_type}
                                                      stake={inv?.stake}
                                                    />
                                                  </span>
                                                ))
                                              : "--"}
                                          </Link>
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[12%]'>
                                    {row?.deal_date
                                      ? <Link className='hover:text-[#BC8B20] font-medium'
                                        to={{
                                          pathname: routeNames.reCompanyExitsDealById(row?.company_id, row?.id)
                                        }}>
                                        {row.deal_date}
                                      </Link>
                                      : "--"}
                                  </td>
                                  <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[10%]'><a>{row?.exit_status ? row.exit_status : "--"}</a></td>
                                  <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[9%] text-center  pr-[3rem] md:pr-[2rem] lg:pr-[2.5rem]'><a>{row?.deal_amount != 0 || row?.deal_amount!=0.00  ? addCommasToNumber(row.deal_amount) : "--"}</a></td>

                                  <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[3%]'>
                                  {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={1} setReload={setReload}/>
                                 : (<div className="flex justify-center">
                                      <AddNotePopover key={row.company_id} companyId={row.company_id} companyName={row.company} type_id={1} sub_type_master_id={sub_type_master_id}/>
                                    </div>)  
                                  }
                                  </td>
                            
                                </tr>
                              )
                            })
                            : menu == "re-ma" ?
                              rows?.map((row, i) => {
                                return (
                                  <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                      <td className="md:w-[13%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                      <NewLogo id={row.company_id} name={row.company} menu={"re"}/>
                                        <div className="whitespace-normal break-words">
                                          <OverflowToolTip
                                            className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                            component={
                                              <NavLink
                                                className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                                state={{ prevPath: location.pathname }}
                                                to={{
                                                  pathname: routeNames.reCompanyById(row.company_id),
                                                  search: getSection(Constants.primaryMasterSubIds.re_others_ma_id),
                                                }}
                                              >
                                                {row?.company ? row.company : "--"}
                                              </NavLink>
                                            }
                                          />
                                        </div>
                                      </td>
                                    <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[6%]'><a>{row?.city ? row.city : "--"}</a></td>
                                    <td className="whitespace-normal break-words px-5 py-3 font-[Squel Sans] w-[17%]">
                                      {row?.acquirer ? (
                                        <TwolineOverflowToolTip
                                          className="w-[10rem] line-clamp-2 leading-relaxed"
                                          component={
                                            <span className="whitespace-normal break-words">{row?.acquirer}</span>
                                          }
                                        />
                                      ) : (
                                        "--"
                                      )}
                                    </td>
                                    <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[4%]'>
                                      {row?.deal_date? <Link className='hover:text-[#BC8B20] font-medium'
                                        to={{
                                          pathname: routeNames.reCompanyOtherMaDealById(row?.company_id, row?.id)
                                        }}>
                                        {row.deal_date}
                                      </Link> : "--"}
                                    </td>
                                    <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[3%] text-center  pr-[2.8rem]'><a>{row?.deal_amount !=0 || row?.deal_amount !=0.00  ? addCommasToNumber(row.deal_amount) : "--"}</a></td>

                                    {/* <td width="15%" className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] '><a>{row?.exit_status ? row.exit_status : "--"}</a></td> */}
                                     <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[1%]'>
                                     {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={1} setReload={setReload}/>
                                 :  <AddNotePopover key={row.company_id} companyId={row.company_id} companyName={row.company} type_id={1} sub_type_master_id={sub_type_master_id}/>
                                     }
                                    </td>
                              
                                  </tr>
                                )
                              })
                              : menu == "re-ipo" ?
                                rows?.map((row, i) => {
                                  return (
                                    <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                      <td className="md:w-[25%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                      <NewLogo id={row.company_id} name={row.company} menu={"re"}/>
                                        <div className="whitespace-normal break-words">
                                          <OverflowToolTip
                                            className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                            component={
                                              <NavLink
                                                className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                                state={{ prevPath: location.pathname }}
                                                to={{
                                                  pathname: routeNames.reCompanyById(row.company_id),
                                                  search: getSection(Constants.primaryMasterSubIds.re_backed_ipos_id),
                                                }}
                                              >
                                                {row?.company ? row.company : "--"}
                                              </NavLink>
                                            }
                                          />
                                        </div>
                                      </td>

                                      <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[12%]'><a>{row?.city ? row.city : "--"}</a></td>

                                      {/* <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[12%]'>


                                        <OverflowToolTip className="sm:max-w-[17rem] 2xl:max-w-[30rem]" component={
                                          <Link rel="noopener noreferrer"
                                          className='hover:text-[#BC8B20] font-semibold'
                                            state={{ prevPath: location.pathname }}
                                            to={routeNames.reInvestorById(row.investor_id)}>
                                            {row?.investor?.length > 0 ?
                                              row?.investor?.map((inves, i) => {
                                                return (
                                                  <>
                                                    {row?.investor?.length - 1 == i ?
                                                      <CustomNavigation mainModule={menu == "re-ipo" ? "re" : ""} module="investor" name={inves.investor_name} entityId={inves?.investor_id} stake={inves?.stake} />
                                                      :
                                                      <CustomNavigation mainModule={menu == "re-ipo" ? "re" : ""} module="investor" name={inves.investor_name + ', '} entityId={inves?.investor_id} stake={inves?.stake} />
                                                    }
                                                  </>
                                                )
                                              })
                                              : "--"}

                                          </Link>
                                        } />
                                      </td> */}
                                      <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[11%]'>

                                        <OverflowToolTip className="w-[10rem]" component={
                                          <a>{row?.sector ? row.sector : "--"}</a>
                                        } />


                                      </td>

                                      <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[4%] '>
                                        {row?.date? <Link className='hover:text-[#BC8B20] font-medium'
                                          to={{
                                            pathname: routeNames.reCompanyBackedIPOSDealById(row?.company_id, row?.id)
                                          }}>
                                          {row.date}
                                        </Link> : "--"}
                                      </td>
                                      <td className='whitespace-nowrap  py-3 font-[Squel Sans] text-right pr-[3rem] lg:pr-[3rem] 3xl:pr-[3.5rem] w-[6%]'><a>{row?.ipo_size !=0 ? addCommasToNumber(row.ipo_size) : "--"}</a></td>

                                      <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[7%]'><a>{row?.exit_status ? row.exit_status : "--"}</a></td>
                                   <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[1%]'>
                                   {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={1} setReload={setReload}/>
                                 : (<div className="flex justify-center">
                                      <AddNotePopover key={row.company_id} companyId={row.company_id} companyName={row.company} type_id={1} sub_type_master_id={sub_type_master_id}/>
                                    </div>)  
                                   }
                                      </td>
                                
                                    </tr>
                                  )
                                })
                                : ""
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>


                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {
        data?.num_companies && rows?.length > 0 ? (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data?.num_companies}
            pageSize={20}
            onPageChange={(page) => findActivePage(page)}
          />

        ) : (
          ""
        )
      }
    </>
  )
}

export default Table
