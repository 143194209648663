import { useState, useMemo, useContext, useEffect, useCallback } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../../Components/Pagination'
import { reGetCompaniesUrl, rowsPerPageData } from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../Pages/addNote";
import '../TableView.css'
import axios from 'axios';
import { UserContext } from '../../Contexts/UserContext';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import CustomNavigation from '../../Utils/CustomNavigation';
import routePathSegments, { routeNames } from '../../routeSegments';
import OverflowToolTip from '../shared/OverflowToolTip';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import { getReAdvisor,getPEGlobalSearchCompaniesApi } from '../../Config/config'
import { getSection } from '../../Utils/utils'
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';
import NewLogo from '../NewLogo';


const Table = ({ columns, menu, advisor_type, order, orderBy ,sub_type_master_id}) => {
  const { monthPickerValue, setMonthPickerValue, isMonthPickerChanged, setIsMonthPickerChanged, isTagClosed, setIsTagClosed, refineSearchFilters ,groupId,resetDealsByFilter} = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, tableAdvisorId, setAllDealIds,setTableAdvisorId, setIsLoad, isDataLoad, setIsDataLoad, currentPage, setCurrentPage,setCurrentFilter, globalSearchResult} = useContext(TableDataContext);
  const[reload, setReload] = useState(false)
  const [activePage, setActivePage] = useState(currentPage)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order:"asc", orderBy:"advisor_name_sort" })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false


  /**
   * The function `findActivePage` sets the current page, renders a table with specific parameters, and
   * updates the load status.
   * @param page - The `page` parameter represents the current page number. It is used to update the
   * current page state and pass it to the `tableRender` function.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order ,groupId)
    setIsLoad(true)
    // setIsDataLoad(false)
  }
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])


  const dealCategory = menu == "re-ladvisor"
  ? "RE_INV"
  : menu == "re-advisorexit"
    ? "RE_EXIT" 
      : menu == "re-advisor-otherma"  ?       
      "OTHER-MA" 
      :""


  /* The above code is a JavaScript function that is used to render a table. It takes several
  parameters such as page number, month range, column name, order direction, and group ID. */
  const tableRender = useCallback((page, month, column, order ,groupId) => {
    setIsMonthPickerChanged(0)
   const category = menu == "re-ladvisor" ? "re-investments" : menu == "re-advisorexit" ? "re-exits" :
      menu == "re-advisor-otherma" ? "other-manda" : "";
    
    const globalSearchCategory = category.replace("-", "_")+"_ids";

    axios.post(getReAdvisor, 
    {
      category,
      from : isFromGlobalSearch() ? 'gs' : '',
        date_range: {
                from_date: moment(month[0]).format("YYYY-MM-DD"),
                to_date: moment(month[1]).format("YYYY-MM-DD"),
              },

      "type": menu == "re-ladvisor" ? "re_investment_advisor" : menu == "re-advisorexit" ? "re_exit_advisor" : menu == "re-advisor-otherma" ? "re_other_ma_advisor"
        : "",
      "advisor_type": advisor_type,
      "page": page,

      "order_by": {
        "column": column,
        "direction": order
      },
      // operator : refineSearchFilters[location.pathname]?.deals_operator,
      company_ids : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
      // ladvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id),
      investor_ids : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id) ,
      advisor_ids: isFromGlobalSearch() ? globalSearchResult?.re_advisor?.length === 0
      ? [0] : globalSearchResult?.re_advisor
      : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id),
      "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
      "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
        "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
        "to": refineSearchFilters[location.pathname]?.dealRange[1].name
      },
      "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(type => type.id),
      "sector_type": refineSearchFilters[location.pathname]?.fundSector?.map(sector => sector.id),
      "sub_sector": refineSearchFilters[location.pathname]?.subSector?.map(sector => sector.id)

      ,
      group:{  
        group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
        sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
  }, 
    },

      {
        signal: abortRef.current.signal,
        headers: {
          "Authorization": getToken()
        },
       

      }).then(response => {
        if (response?.data?.message == "Success") {
          setCurrentFilter(response.config.data)
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.advisors)
          setData(response?.data)
          setTableAdvisorId([])
          setTableAdvisorId(response?.data?.all_advisor_ids)
          setAllDealIds(response?.data?.all_deal_ids)
          // console.log(response?.data?.companies,"api")
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [monthPickerValue, refineSearchFilters]);


  /* The above code is a useEffect hook in JavaScript. It is used to perform side effects in functional
  components. */
  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order ,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue ,groupId,reload] );


  const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = sortedRows
  const count = filteredRows.length


  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   * @param accessor - The `accessor` parameter is a value that represents the property or key of the
   * object that you want to sort by. It is used to identify the specific property or key that you want
   * to sort the data by.
   * @param order - The `order` parameter is used to specify the sorting order. It can have two
   * possible values: 'asc' for ascending order and 'desc' for descending order.
   */
  const handleSort = (accessor, order) => {
    setRows([])
    setIsLoad(true);
    setCurrentPage(currentPage)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }
  var location = useLocation();

  /**
   * The function `addCommasToNumber` takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number that you want to add commas to.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="table w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className="tableTd px-5 py-2 font-sans_book_body" key={i}>
                          <span className='flex'>
                            <OverflowToolTip component={
                              <a className='cursor-text hover:text-[#000000]'>
                                {column.accessor === "add" && groupId?.myList?.length > 0 ? "Delete" :
                                  column.accessor === "add" ? (
                                    <div className="flex flex-col text-center">
                                      <span>Create</span>
                                      <span>your list</span>
                                    </div>
                                  ) :
                                  column.accessor === "to_company_investments" ? (
                                    <div className="flex flex-col text-center">
                                      <span>To Company</span>
                                      <span>(Investments)</span>
                                    </div>
                                  ) 
                                  : column.label ? column.label : "--"}
                              </a>
                            } />
                            {column.accessor !== "add" && (
                              <button onClick={() => handleSort(column.accessor, sort.order)}>
                                {sortIcon()}
                              </button>
                            )}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[13pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rows.length > 0 ?
                        menu == "re-ladvisor" ?

                          rows?.map((row, i) => {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                  <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                  <NewLogo id={row.advisor_id} menu={"re_advisor"} name={row.advisor_name} /> 
                                    <div className="whitespace-normal break-words">
                                      <OverflowToolTip
                                        className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                        component={
                                          <NavLink
                                            className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                            state={{ prevPath: location.pathname }}
                                            to={{
                                              pathname: routeNames.reTransactionAdvisorById(row.advisor_id),
                                              search: getSection(Constants.primaryMasterSubIds.re_transaction_investment_id),
                                            }}
                                          >
                                            {row?.advisor_name ? row.advisor_name : "--"}
                                          </NavLink>
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td width="2%" className="min-w-[140px]  px-5 title text-center xl:pr-[3.2rem] 2xl:pr-[4rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                  <td width="4%" className="min-w-[155px] px-5 text-center lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[4.3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                  <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem]  2xl:pr-[3.9rem] 3xl:pr-[5rem] lg:pr-[2.7rem] " > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                  <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem] 2xl:pr-[3.5rem] 3xl:pr-[4rem] lg:pr-[3rem] "   ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                  <td width="1%" className=" px-[10px] py-3 font-sans_book_body " >
                                  {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.t_advisor_id} setReload={setReload}/>
                                 :<AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.t_advisor_id} sub_type_master_id={sub_type_master_id}/>

                                  }</td>
                              </tr>
                            )
                          })

                          :
                          menu == "re-advisorexit" ?
                            rows?.map((row, i) => {
                              return (
                                <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                  <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                  <NewLogo id={row.advisor_id} menu={"re_advisor"} name={row.advisor_name}/> 
                                    <div className="whitespace-normal break-words">
                                      <OverflowToolTip
                                        className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                        component={
                                          <NavLink
                                            className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                            state={{ prevPath: location.pathname }}
                                            to={{
                                              pathname: routeNames.reTransactionAdvisorById(row.advisor_id),
                                              search: getSection(Constants.primaryMasterSubIds.re_transaction_exits_id),
                                            }}
                                          >
                                            {row?.advisor_name ? row.advisor_name : "--"}
                                          </NavLink>
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td width="2%" className="min-w-[140px]  px-5 title text-center xl:pr-[3.2rem] 2xl:pr-[3rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                  <td width="4%" className="min-w-[155px] px-5 text-center lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                  <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem]  2xl:pr-[3rem] 3xl:pr-[5rem] lg:pr-[2.7rem] " > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                  <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem] 2xl:pr-[3rem] 3xl:pr-[4rem] lg:pr-[3rem] "   ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                  <td width="1%" className=" px-[10px] py-3 font-sans_book_body " >
                                  {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.t_advisor_id} setReload={setReload}/>
                                 :<AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.t_advisor_id} sub_type_master_id={sub_type_master_id}/>

                            }</td>
                                </tr>
                              )
                            })

                            : menu == "re-advisor-otherma" ?
                              rows?.map((row, i) => {
                                return (
                                  <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                    <td className="md:w-[23%] pl-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                                    <NewLogo id={row.advisor_id} menu={"re_advisor"} name={row.advisor_name}/> 
                                      <div className="whitespace-normal break-words">
                                        <OverflowToolTip
                                          className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                                          component={
                                            <NavLink
                                              className="flex text-start leading-[1.8rem] lg:inline whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                              state={{ prevPath: location.pathname }}
                                              to={{
                                                pathname: routeNames.reTransactionAdvisorById(row.advisor_id),
                                                search: getSection(Constants.primaryMasterSubIds.re_transaction_otherma_id),
                                              }}
                                            >
                                              {row?.advisor_name ? row.advisor_name : "--"}
                                            </NavLink>
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td width="2%" className="min-w-[140px]  px-5 title text-center xl:pr-[3.2rem] 2xl:pr-[4rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                    <td width="4%" className="min-w-[155px] px-5 text-center lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[4.3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                    <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem]  2xl:pr-[3.9rem] 3xl:pr-[5rem] lg:pr-[2.7rem] " > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                    <td width="2%" className="min-w-[120px]  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem] 2xl:pr-[3rem] 3xl:pr-[4rem] lg:pr-[3rem] "   ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                    <td width="1%" className=" px-[10px] py-3 font-sans_book_body " >
                                    {groupId?.myList?.length >0 ? 
                                  <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.t_advisor_id}  setReload={setReload}/>
                                  : <AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.t_advisor_id} sub_type_master_id={sub_type_master_id}/>
                                  }</td>
                                  </tr>
                                )
                              })
                              : ""
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>


                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_advisors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_advisors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />
      ) : (
        ""
      )}

    </>
  )
}

export default Table
