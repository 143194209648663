import { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import CompanyLogo from '../CompanyLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../Pages/addNote";
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { rowsPerPageData, getCfsCompaniesApi, dateRangeFilter,getPEGlobalSearchCompaniesApi } from '../../Config/config'
import { TableDataContext } from '../../Contexts/TableDataContext'
import axios from "axios";
import CustomNavigation from '../../Utils/CustomNavigation';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';
import NewLogo from '../NewLogo';

const isEmptyObject = (object) => Object.values(object).every(x => x == null || x === '' || x === undefined);


/**
 * The Table component is a reusable table component that takes in columns, menu, order,
 * and orderBy as props and renders a table with sorting and pagination functionality.
 * @returns The `Table` component is returning a JSX element that represents a table with dynamic data.
 */
const Table = ({ columns, menu, order, orderBy ,sub_type_master_id}) => {

  const { rows, setRows, data, setData, isLoad, setIsLoad, isDataLoad, setIsDataLoad, totalNoOfCompanies, setTotalNoOfCompanies, currency, setTableCompanyId, currentPage, setCurrentPage,setCurrentFilter, globalSearchResult} = useContext(TableDataContext);
  const { refineSearchFilters,groupId, setIocPayload, iocPayload,setGroupId } = useContext(PeFilterContext);
  const [activePage, setActivePage] = useState(currentPage)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)
  const location = useLocation();
    const navigate = useNavigate();

  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
  }
  const tableRender = useCallback((page, column, order,groupId) => {

    //Charge based filters
    const selectedChargeAmt = refineSearchFilters[location.pathname]?.chargeAmount?.at(0)?.value;
    const selectedChargeDate = refineSearchFilters[location.pathname]?.chargeDate?.at(0)?.value;
    const selectedChargeCompanyName = refineSearchFilters[location.pathname]?.chargeCompanies?.map(company => company.id);
    const selectedChargeHolderName = refineSearchFilters[location.pathname]?.chargeHolders?.map(holder => parseInt(holder.id, 10));
    const chargeStateIds = refineSearchFilters[location.pathname]?.chargeState?.map(value => value.id) ?? [];
    const chargeCityIds = refineSearchFilters[location.pathname]?.chargeCity?.map(value => value.id) ?? [];

    //growth based filters
    const IsCAGR = refineSearchFilters[location.pathname]?.growthCAGR?.at(0)?.value;
    const growthOperator = refineSearchFilters[location.pathname]?.growthOperator?.at(0)?.value;
    const yearMatchType = refineSearchFilters[location.pathname]?.growthYear?.at(0)?.value;
    const growthsTemp = refineSearchFilters[location.pathname]?.growthPercentage?.map(option => ({ key: `${option.value?.value}`, value: option.value?.moreThan }))
    const growthsYearsTemp = refineSearchFilters[location.pathname]?.growthPercentage?.map(option => ({ key: `${option.value?.value}_years`, value: option.value?.years }))
    //key value added dynamically
    const growths = growthsTemp?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    const growthsYears = growthsYearsTemp?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    //ratio based filters
    const ratioOperator = refineSearchFilters[location.pathname]?.ratioOperator?.at(0)?.value;
    const ratioyearMatchType = refineSearchFilters[location.pathname]?.ratioYear?.at(0)?.value;
    const ratioTemp = refineSearchFilters[location.pathname]?.ratioPercentage
      ?.map(option => option.value)
      ?.map(option => ({ [`${option.filterKey}_min`]: option.min, [`${option.filterKey}_max`]: option.max }))

    const ratio = ratioTemp?.reduce(
      (obj, item) => Object.assign(obj, { ...item }), {});

    //financial based filters

    const financialOperator = refineSearchFilters[location.pathname]?.financialOperator?.at(0)?.value;
    const financialyearMatchType = refineSearchFilters[location.pathname]?.financialYear?.at(0)?.value;
    const financialCurrency = refineSearchFilters[location.pathname]?.financialCurrencyType?.at(0)?.value;
    const financialTemp = refineSearchFilters[location.pathname]?.financialPercentage
      ?.map(option => option.value)
      ?.map(option => ({ [`${option.value}_min`]: option.min, [`${option.value}_max`]: option.max }))

    const financial = financialTemp?.reduce(
      (obj, item) => Object.assign(obj, { ...item }), {});


    const growthReq = {
      "cagr": IsCAGR,
      "range_type": growthOperator,
      "year_match_type": yearMatchType,
      ...growths,
      ...growthsYears
    };

    const ratioReq = {
      "range_type": ratioOperator,
      "year_match_type": ratioyearMatchType,
      ...ratio,
    };

    const financialReq = {
      "range_type": financialOperator,
      "year_match_type": financialyearMatchType,
      "unit": financialCurrency,
      ...financial,
    };

    const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

    const payload = {
      company: refineSearchFilters[location.pathname]?.chargeCompanies || [],
      charge_holder_name: refineSearchFilters[location.pathname]?.chargeHolders || [],
      charge_date: selectedChargeDate,
      charge_amount: selectedChargeAmt,
      state: refineSearchFilters[location.pathname]?.chargeState || [],
      city: refineSearchFilters[location.pathname]?.chargeCity || []
    };

    setIocPayload(payload);

    const isEmptyIocPayload = payload && Object.entries(payload).every(
      ([key, value]) => Array.isArray(value) ? value.length === 0 : value === undefined
  );
  const excludedKeys = ["chargeAmount", "chargeCity", "chargeCompanies", "chargeDate", "chargeHolders", "chargeState", "syndication", "operator","transactionStatus"];
    const isEmptyRefineSearchFilters = refineSearchFilters[location.pathname] && Object.entries(refineSearchFilters[location.pathname])?.every(
        ([key, value]) => excludedKeys.includes(key) || (Array.isArray(value) ? value.length === 0 : value === null || value === 0 || value === undefined)
    );
//   if( isEmptyRefineSearchFilters && refineSearchFilters[location.pathname]?.syndication?.map(value => value.value)[0] === '0'){
// // setTimeout(() => {
  
//   setGroupId({});
//           navigate("ioc", { 
//               state: { iocPayload: iocPayload },
//               replace: true  
//           });
// // }, 100);
//   }
    axios(`${getCfsCompaniesApi}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data: {
        "currency": currency,
        "page": page,
        "order_by": {
          "column": column,
          "direction": order
        },
        from: isFromGlobalSearch() ? 'gs' : null,
        companyIds: isFromGlobalSearch() ? globalSearchResult?.cfs_company?.length === 0 ? [0] : globalSearchResult?.cfs_company : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        industry: refineSearchFilters[location.pathname]?.industry?.map(value => value.id), //Industries //[1],
        sector: refineSearchFilters[location.pathname]?.sector?.map(value => value.id),
        company_type: refineSearchFilters[location.pathname]?.companyType?.map(type => type.id),
        transaction_status: refineSearchFilters[location.pathname]?.transactionStatus?.map(value => value.id),
        region: refineSearchFilters[location.pathname]?.region?.map(region => region.id),
        state: refineSearchFilters[location.pathname]?.state?.map(state => state.id),
        city: refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        year_founded: refineSearchFilters[location.pathname]?.yearFounded?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.yearFounded[0]?.name,
          "to": refineSearchFilters[location.pathname]?.yearFounded[1]?.name
        },
        auditor_name: refineSearchFilters[location.pathname]?.auditorName?.map(audtior => audtior.name),
        result_type: refineSearchFilters[location.pathname]?.financialType?.at(0)?.id ?? "Both",
        financials: isEmptyObject(financialReq) ? null : financialReq,
        ratios: isEmptyObject(ratioReq) ? null : ratioReq,
        has_ioc : refineSearchFilters[location.pathname]?.syndication?.map(value => value.value)[0] === '0' 
            ? true 
          : refineSearchFilters[location.pathname]?.syndication?.map(value => value.value)[0] === '1' 
            ? false 
          : undefined,
        charges: {
          company: selectedChargeCompanyName,
          charge_holder_name: selectedChargeHolderName,
          charge_date: selectedChargeDate,
          // result_type: refineSearchFilters[location.pathname]?.syndication?.map(value => value.value)[0] === '0' 
          //     ? "Yes" 
          //   : refineSearchFilters[location.pathname]?.syndication?.map(value => value.value)[0] === '1' 
          //     ? "No" 
          //   : undefined,
          charge_amount: selectedChargeAmt,
          "state": chargeStateIds,
          "city": chargeCityIds,
        },
        growth: isEmptyObject(growthReq) ? null : growthReq,
        tag: {
          operator: refineSearchFilters[location.pathname]?.operator,
          data: refineSearchFilters[location.pathname]?.tags?.map(tag => tag.name)
        },
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
        }
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },

    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          // console.log(response,'cfs table response');  
          setCurrentFilter(response.config.data)     
          setTotalNoOfCompanies(response?.data?.num_companies)
          setIsLoad(false)
          setIsDataLoad(false)
          setRows([])
          setRows(response?.data?.companies)
          setData(response?.data)
          setTableCompanyId(response?.data?.all_company_ids)
          console.log(response?.data, 'cfs table response');
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [currency, refineSearchFilters[location.pathname]]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, sort.orderBy, sort.order,groupId)
    return () => {
      abortController.abort();
    }
  }, [tableRender, currency, refineSearchFilters,groupId ,reload]);

  const addNote = (id) => {
    setShowNote(!showNote)
    setShowId(id)
  }

  const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, currentPage, rowsPerPage)
  console.log(calculatedRows, "cfs calculated")

/**
 * The function `handleSort` is used to handle sorting of table rows based on a given accessor and
 * order.
 * @param accessor - The accessor parameter is a value that represents the property or key of the
 * object that you want to sort by. It is used to access the specific property or key in the object.
 * @param order - The order parameter determines the sorting order of the data. It can have two
 * possible values: 'asc' for ascending order and 'desc' for descending order.
 */
  const handleSort = (accessor, order) => {
    setRows([])
    setCurrentPage(currentPage)
    setIsLoad(true);
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }
  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)

  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className="overflow-x-auto scrollbar  rounded-b-[6px]">
              <table className="w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB]  text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] font-semibold  text-[#666666]">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (

                        <th className={ "whitespace-nowrap py-2 " + ( i>=1 && i<=3  ? "lg:px-0 px-8" : i==4  ? "lg:pl-[6rem] px-8":"px-5 ") }   key={column.accessor}>
                          <span className={(i !== 5 ? "flex " : "")  +( i>=1 && i<=3  ? "justify-end " : i == 5 ? " block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem] " :"")}>
                            <a>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": 
                              column.accessor === "add" ? (
                                    <div className="flex flex-col text-center">
                                      <span>Create</span>
                                      <span>your list</span>
                                    </div>) :
                            column.label ? column.label : "--"}</a>
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows.length > 0 ?
                        rows?.map((row, i) => {
                          return (
                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="leading-relaxed px-5 py-3 font-sans_book_body card-animate"
                                style={{ whiteSpace: "pre-wrap" }}> 
                              <div className="flex items-start lg:items-center space-x-2">
                              <NewLogo id={row.company_id} menu={'cfs'} name={row.s_company_name} />

                                <OverflowToolTip
                                  className="w-[20rem] lg:max-w-full"
                                  component={
                                    <NavLink
                                      className="flex text-start lg:inline lg:whitespace-normal break-words hover:text-[#BC8B20] font-medium"
                                      to={routeNames.cfsCompanyPageById(row.company_id)}
                                    >
                                      {row?.s_company_name ? row.s_company_name : "--"}
                                    </NavLink>
                                  }
                                />
                              </div>
                            </td>
                              <td  className="pr-14 lg:pr-6 py-3 font-sans_book_body text-right w-[5%]"><p className=''>{row.revenue !=0 || row.revenue !=0.00 ? (addCommasToNumber(row.revenue)) : "--"}</p></td>
                              <td  className="pr-14 lg:pr-6 py-3 font-sans_book_body text-right w-[13%]"><p>{row.ebitda !=0 || row.ebitda !=0.00 ? (addCommasToNumber(row.ebitda)) : "--"}</p></td>
                              <td  className="pr-14 lg:pr-6 py-3 font-sans_book_body text-right w-[12%]"><p >{row.pat !=0 || row.pat !=0.00 ? (addCommasToNumber(row.pat)) : "--"}</p></td>
                              <td  className="px-8 py-3 font-sans_book_body whitespace-nowrap lg:pl-[6rem] w-[9%]"><p>{row.detailed ? row.detailed : "--"}</p></td>
                              <td className={groupId?.myList?.length >0 ? "px-5 py-3 font-sans_book_body  w-[3rem]":"px-5 py-3 font-sans_book_body "}>
                              {groupId?.myList?.length >0 ? 
                                  <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.companiesId} setReload={setReload}/>
                                    :(<div className="flex justify-center">
                                        <AddNotePopover  key={row.company_id} companyId={row.company_id} companyName={row.s_company_name} type_id={Constants.moduleIds.companiesId} sub_type_master_id={sub_type_master_id}/>
                                      </div>) 
                        }</td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {/* <pre>  {JSON.stringify(refineSearchFilters, null, 2)}  </pre> */}


              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_companies && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_companies}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
    </>
  )
}

export default Table